import React, { Component } from 'react';
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import { getTransactionEmailLogsByTransactionId, resendReceiptEmail, resendTicketEmail } from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage, prettyPrintDate } from '../../../vibe/helpers/util';

class TransactionEmails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resend_email: {
                receipt: {
                    email: null,
                    message: null
                },
                ticket: {
                    email: null,
                    message: null
                }
            },
            emailLogs: []
        }
    }

    componentDidMount() {
        this.getLogs()
    }

    getLogs() {

        if (this.isInvalidUser() || !this.props?.transaction?.id) {
            return
        }

        getTransactionEmailLogsByTransactionId(this.props.transaction.id, (emailLogs) => {
            this.setState({ emailLogs: emailLogs })
        }, (error) => {
            this.setState({ emailLogs: [] })
        })
    }

    onResendReceipt(transaction) {
        const email = this.state?.resend_email?.receipt?.email
        const confirm = window.confirm("Resend receipt to " + email + '?')
        if (confirm) {
            resendReceiptEmail(transaction.id, email, (result) => {
                this.getLogs()
            }, (error) => {
                const message = parseErrorMessage(error)
                this.setMessage('receipt', "Error: " + message)
            })
        }
    }

    onResendTicketsEmail(transaction) {
        const email = this.state?.resend_email?.ticket?.email
        const confirm = window.confirm("Resend ticket email to " + email + '?')
        if (confirm) {
            resendTicketEmail(transaction.id, email, (result) => {
                this.getLogs()
            }, (error) => {
                const message = parseErrorMessage(error)
                this.setMessage('ticket', "Error: " + message)
            })
        }
    }

    emailButtonEnabled(key) {
        if (this.state?.resend_email?.[key]?.email?.length > 0) {
            return true
        }
        return false
    }

    setMessage(key, value) {
        var resendEmail = this.state.resend_email
        resendEmail[key].message = value
        this.setState({ resend_email: resendEmail })
    }

    onChangeResendEmail(key, value) {
        var resendEmail = this.state.resend_email
        resendEmail[key].email = value
        this.setState({ resend_email: resendEmail })
    }

    renderMessages() {
        const receiptMessage = this.state.resend_email?.receipt?.message
        const ticketMessage = this.state.resend_email?.ticket?.message

        const messages = []
        if (receiptMessage) {
            messages.push({key: 'receipt', message: receiptMessage})
        }

        if (ticketMessage) {
            messages.push({key: 'ticket', message: ticketMessage})
        }

        return <>
            {messages.map((item) => {
                return <p key={item.key}>{item.message}</p>
            })}
        </>
    }

    isInvalidUser() {
        if (!this.props.user || this.props.user.role !== 'Admin') {
            return true
        }
        return false
    }

    renderEmailLogs(emailLogs) {
        if (!emailLogs || emailLogs.length <= 0) {
            return null
        }

        return emailLogs.map((emailLog) => {
            const createdAt = prettyPrintDate(emailLog.created_at)
            const data = JSON.parse(emailLog.data)
            
            var dataItems = []
            let keys = Object.keys(data)
            for (var key of keys) {
                dataItems.push(key + ": " + data[key])
            }

            return <div key={emailLog.id}>{emailLog.action} {emailLog.model} | {createdAt} | {dataItems.join(", ")}</div>
        })
    }

    render() {
        if (this.isInvalidUser()) {
            return null
        }

        return <div>
            <Card><h4 style={{paddingLeft: "20px", paddingTop:"20px"}}>Emails</h4>
                {this.renderMessages()}
                <CardBody className='card-body d-flex flex-column flex-lg-row min-width-zero'>

                    <FormGroup>
                        <Label for="receipt_email">Receipt Email</Label>
                        <Input style={{minWidth: '350px'}} type="text" name="receipt_email" id="receipt_email" onChange={(e) => this.onChangeResendEmail('receipt', e.target.value)} />
                        <Button disabled={!this.emailButtonEnabled('receipt')} className="d-inline-block px-5 mt-3" color="primary" onClick={() => this.onResendReceipt(this.props.transaction)}>Resend Receipt email</Button>
                    </FormGroup>

                    {this.props.isTicket && <><FormGroup className='ml-5'>
                        <Label for="ticket_email">Ticket Email</Label>
                        <Input style={{minWidth: '350px'}} type="text" name="ticket_email" id="ticket_email" onChange={(e) => this.onChangeResendEmail('ticket', e.target.value)} />
                        <Button disabled={!this.emailButtonEnabled('ticket')} className="d-inline-block px-5 mt-3" color="primary" onClick={() => this.onResendTicketsEmail(this.props.transaction)}>Resend Ticket email</Button>
                    </FormGroup>
                    </>}

                </CardBody>

                <CardBody>
                    {this.renderEmailLogs(this.state.emailLogs)}
                </CardBody>

            </Card>
        </div>
    }

}

export default TransactionEmails;

