import { NEW, } from '../../../../elements/form/FormAction';
import { hasOption } from '../../../../../vibe/helpers/optionHelper';
import Options from '../../../common/Options';
import { v4 as uuidv4 } from 'uuid';

export const BUTTON_TEXT_OPTIONS = [
    {value: 1, label: 'Köp biljett'}, 
    {value: 2, label: 'Boka bord'}
]

export const KEY_BUTTON_TEXT = "bouncer_button_text"
export const KEY_BUTTON_BG_COLOR = "bouncer_button_bg_color"
export const KEY_BUTTON_TEXT_COLOR = "bouncer_button_text_color"

class OccurrenceOptions extends Options {

    onAddDefaultValues(options) {
        if (!hasOption(options, KEY_BUTTON_TEXT)) {
            options.push({id: uuidv4(), _action: NEW, key: KEY_BUTTON_TEXT, value: BUTTON_TEXT_OPTIONS[0].label})
        } 
    }

}

export default OccurrenceOptions