import { Config } from '../../config';
import Cookies from 'universal-cookie';
import { formatDate } from './dateHelper';
import { removeTokens } from '../../views/pages/login/Token.js';
import { removeNullValues } from './util';

const waitUntil = (condition) => {
    return new Promise((resolve) => {
        let interval = setInterval(() => {
            if (!condition()) {
                return
            }

            clearInterval(interval)
            resolve()
        }, 100)
    })
}

export async function getCurrentUser(onSuccess, onError) {
    var url = Config.api() + '/users/me'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                if (result && result.error && token) {
                    if (result.error.statusCode === 403) {
                        removeTokens()
                        window.location.href = '/login'
                    }
                }
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err.message)
        })
}

export async function getMembershipByOrganizationIds(organizationIds, onSuccess, onError) {
    var organizationUrl = Config.api() + '/organizations/' + organizationIds + '/membership'
    var token = await getToken()
    fetch(organizationUrl, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError('error adding membership, maybe this organization has no active memberships?')
            }
        })
        .catch(err => {
            onError('error adding membership, request error')
        })
}

export async function getTransactionsByMemberId(memberId, onSuccess, onError) {
    var url = Config.api() + '/transactions/members/' + memberId
    get(url, onSuccess, onError)
}

export async function fetchMember(memberId, onSuccess, onError) {
    var url = Config.api() + '/members/' + memberId
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                if (result.error) {
                    onError(result.error.message)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function fetchMembership(membershipId, onSuccess, onError) {
    var url = Config.api() + '/memberships/' + membershipId
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function fetchMemberMemberships(memberId, onSuccess, onError) {
    var url = Config.api() + '/members/' + memberId + '/member-memberships'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function createMembership(membership, onSuccess, onError) {
    var url = Config.api() + '/memberships/'
    var token = await getToken()
    membership = removeNullValues(membership)
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(membership)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function createOrganizationEvent(organizationEvent, onSuccess, onError) {
    var url = Config.api() + '/organization-events/'
    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(organizationEvent)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function patchOrganizationEvent(id, organizationEvent, onSuccess, onError) {
    var url = Config.api() + '/organization-events/' + id
    var token = await getToken()
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(organizationEvent)
    })
        .then(async (response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                let json = await response.json()
                if (json && json.error) {
                    onError(json.error)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function deleteAllOrganizationProductMedia(mediaIds) {
    for (let deletedMediaId of mediaIds) {
        await new Promise(resolve => {
            deleteOrganizationProductMedia(deletedMediaId, () => {
                resolve(true)
            }, (error) => {
                resolve(false)
            })
        })
    }
}

export async function deleteOrganizationProductMedia(organizationProductMediaId, onSuccess, onError) {
    var url = Config.api() + '/organization-product-media/' + organizationProductMediaId
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function patchAllOrganizationProductMedia(organizationProductMedia) {
    for (let editedOrganizationProductMedia of organizationProductMedia) {
        await new Promise(resolve => {
            patchOrganizationProductMedia(editedOrganizationProductMedia.id, { media_id: editedOrganizationProductMedia.media_id }, () => {
                resolve(true)
            }, (error) => {
                resolve(false)
            })
        })
    }
}

export async function patchOrganizationProductMedia(organizationProductMediaId, data, onSuccess, onError) {
    var url = Config.api() + '/organization-product-media/' + organizationProductMediaId
    var token = await getToken()
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function createAllOrganizationProductMedia(productId, mediaIds) {
    for (let pendingMedia of mediaIds) {
        if (pendingMedia > 0) {
            await new Promise(resolve => {
                createOrganizationProductMedia(productId, pendingMedia, () => {
                    resolve(true)
                }, (error) => {
                    resolve(false)
                })
            })
        }
    }
}

export async function createOrganizationProductMedia(organizationProductId, mediaId, onSuccess, onError) {

    let data = {
        organization_product_id: organizationProductId,
        media_id: mediaId
    }

    var url = Config.api() + '/organization-product-media/'
    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function createOrganizationProduct(organizationProduct, onSuccess, onError) {
    var url = Config.api() + '/organization-products/'
    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(organizationProduct)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function patchOrganizationProduct(organizationProduct, onSuccess, onError) {
    var url = Config.api() + '/organization-products/' + organizationProduct.id
    var token = await getToken()
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(organizationProduct)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function deleteOrganizationPost(organizationPostId, onSuccess, onError) {
    var url = Config.api() + '/organization-posts/' + organizationPostId
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function createOrganizationPost(organizationPost, onSuccess, onError) {
    var url = Config.api() + '/organization-posts/'
    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(organizationPost)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function createOrganizationDetails(organizationDetails, onSuccess, onError) {
    var url = Config.api() + '/organization-details/'
    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(organizationDetails)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function patchOrganizationPost(organizationPost, onSuccess, onError) {
    var url = Config.api() + '/organization-posts/' + organizationPost.id
    var token = await getToken()
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(organizationPost)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function patchOrganizationDetails(organizationDetails, onSuccess, onError) {
    var url = Config.api() + '/organization-details/' + organizationDetails.id
    var token = await getToken()
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(organizationDetails)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function promisifyOrFalse(func, ...args) {
    return new Promise((resolve, reject) => {
        func(...args, 
            (result) => resolve(result),
            (error) => resolve(false)
        );
    });
}

export async function promisify(func, ...args) {
    return new Promise((resolve, reject) => {
        func(...args, 
            (result) => resolve(result),
            (error) => reject(error)
        );
    });
}

export async function createOrganizationEventOccurrence(organizationEventOccurrence, onSuccess, onError) {
    var url = Config.api() + '/organization-event-occurrences'
    post(url, organizationEventOccurrence, onSuccess, onError)
}

export async function updateOrganizationEventOccurrence(id, data, onSuccess, onError) {
    var url = Config.api() + '/organization-event-occurrences/' + id
    patch(url, data, onSuccess, onError)
}

export async function deleteOrganizationEventOccurrence(organizationEventOccurrenceId, onSuccess, onError) {
    var url = Config.api() + '/organization-event-occurrences/' + organizationEventOccurrenceId
    _delete(url, onSuccess, onError)
}

export async function createTickets(tickets, onSuccess, onError) {
    var url = Config.api() + '/tickets'
    post(url, tickets, onSuccess, onError)
}

export async function createOccurrenceOption(occurrenceId, option, onSuccess, onError) {
    var url = Config.api() + '/organization-event-occurrences/' + occurrenceId + '/organization-event-occurrence-options'
    post(url, option, onSuccess, onError)
}

export async function patchOccurrenceOption(occurrenceId, optionId, option, onSuccess, onError) {
    const where = {
        id: optionId
    }
    let filter = toEncodedWhereString(where)
    var url = Config.api() + '/organization-event-occurrences/' + occurrenceId + '/organization-event-occurrence-options' + filter
    patch(url, option, onSuccess, onError)
}

export async function createEventOccurrenceTickets(occurrenceId, ticketsId, onSuccess, onError) {
    var url = Config.api() + '/event-occurrence-tickets'
    let data = {
        organization_event_occurrence_id: occurrenceId,
        tickets_id: ticketsId
    }
    post(url, data, onSuccess, onError)
}

export async function deleteEventOccurrenceTicket(ticketsId, onSuccess, onError) {
    var url = Config.api() + '/event-occurrence-tickets/tickets/' + ticketsId
    _delete(url, onSuccess, onError)
}

export async function deleteEventOccurrenceTicketByOccurrence(eventOccurrenceId, onSuccess, onError) {
    var url = Config.api() + '/event-occurrence-tickets/event-occurrence/' + eventOccurrenceId
    _delete(url, onSuccess, onError)
}

export async function deleteTickets(ticketsId, onSuccess, onError) {
    var url = Config.api() + '/tickets/' + ticketsId
    _delete(url, onSuccess, onError)
}

export async function updateTicketsById(id, tickets, onSuccess, onError) {
    var url = Config.api() + '/tickets/' + id
    patch(url, tickets, onSuccess, onError)
}

export async function memberExists(data, onSuccess, onError) {
    var url = Config.api() + '/members/exists'
    post(url, data, onSuccess, onError)
}

export async function addMemberParentMembership(memberId, onSuccess, onError) {
    var url = Config.api() + '/members/' + memberId + '/add-parent-membership'
    post(url, {}, onSuccess, onError)
}

export async function addMemberMembership(memberId, membershipId, onSuccess, onError) {
    var url = Config.api() + '/members/' + memberId + '/add-membership'
    post(url, { membership_id: membershipId }, onSuccess, onError)
}

export async function upsertVerifiedStudentInfo(data, onSuccess, onError) {
    var url = Config.api() + '/verified-student-infos/upsert'
    post(url, data, onSuccess, onError)
}

export async function createInactiveMember(data, notify, onSuccess, onError) {
    var url = Config.api() + '/members/inactive/' + notify
    post(url, data, onSuccess, onError)
}

export async function getOrganization(onSuccess, onError) {
    var url = Config.api() + '/organization'
    get(url, onSuccess, onError)
}

export async function getUserCountryByOrganization(onSuccess, onError) {
    getOrganization((organizations) => {
        if (organizations && organizations.length > 0) {
            onSuccess(organizations[0].country)
        } else {
            onError()
        }
    }, onError)
}

export async function getPackages(onSuccess, onError) {

    let filterObject = {
        order: ['updated_at DESC'],
        include: [
            {
                relation: 'membershipSubscriptions',
                scope: {
                    include: [
                        {
                            relation: 'membership'
                        }
                    ]
                }
            }
        ]
    }

    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/packages' + filter
    get(url, onSuccess, onError)
}

export async function getPackageById(id, onSuccess, onError) {
    const filterObject = {
        include: [
            {
                relation: 'campaigns'
            }
        ]
    }
    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/packages/' + id + filter
    get(url, onSuccess, onError)
}

export async function getPackageMembershipSubscriptionsByPackageId(id, onSuccess, onError) {
    var url = Config.api() + '/packages/' + id + '/package-membership-subscriptions'
    get(url, onSuccess, onError)
}

export async function createPackage(_package, onSuccess, onError) {
    var url = Config.api() + '/packages/'
    post(url, _package, onSuccess, onError)
}

export async function patchPackage(_package, onSuccess, onError) {
    var url = Config.api() + '/packages/' + _package.id
    patch(url, _package, onSuccess, onError)
}

export async function getMembershipSubscriptions(onSuccess, onError) {
    
    let filterObject = {
        order: ['id DESC'],
        include: [
            {
                relation: 'membership',
            }
        ]
    }

    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/membership-subscriptions' + filter
    get(url, onSuccess, onError)
}

export async function createMembershipSubscription(membershipSubscription, onSuccess, onError) {
    var url = Config.api() + '/membership-subscriptions/'
    post(url, membershipSubscription, onSuccess, onError)
}

export async function createMembershipSubscriptionVerificationType(membershipSubscriptionVerificationType, onSuccess, onError) {
    var url = Config.api() + '/membership-subscription-verification-types'
    post(url, membershipSubscriptionVerificationType, onSuccess, onError)
}

export async function deleteMembershipSubscriptionVerificationType(id, onSuccess, onError) {
    var url = Config.api() + '/membership-subscription-verification-types/' + id
    _delete(url, onSuccess, onError)
}

export async function patchMembershipSubscription(data, onSuccess, onError) {
    var url = Config.api() + '/membership-subscriptions/' + data.id
    patch(url, data, onSuccess, onError)
}

export async function getSubscriptionVerificationTypes(membershipSubscriptionId, onSuccess, onError) {
    let filterObject = {
        where: {
            membership_subscription_id: membershipSubscriptionId
        }
    }
    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/membership-subscription-verification-types' + filter
    get(url, onSuccess, onError)
}

export async function getMembershipSubscriptionById(id, onSuccess, onError) {

    let filterObject = {
        include: [
            {
                relation: 'verification_types'
            }
        ]
    }
    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/membership-subscriptions/' + id + filter

    get(url, onSuccess, onError)
}

export async function getVerificationTypes(onSuccess, onError) {
    var url = Config.api() + '/verification-types'
    get(url, onSuccess, onError)
}

export async function createPackageMembershipSubscription(packageMembershipSubscription, onSuccess, onError) {
    var url = Config.api() + '/package-membership-subscriptions'
    post(url, packageMembershipSubscription, onSuccess, onError)
}

export async function patchPackageMembershipSubscription(id, data, onSuccess, onError) {
    var url = Config.api() + '/package-membership-subscriptions/' + id
    patch(url, data, onSuccess, onError)
}

export async function deletePackageMembershipSubscription(id, onSuccess, onError) {
    var url = Config.api() + '/package-membership-subscriptions/' + id
    _delete(url, onSuccess, onError)
}

export function toEncodedFilterString(filter) {
    return '?filter=' + encodeURIComponent(JSON.stringify(filter))
}

export function toEncodedWhereString(where) {
    return '?where=' + encodeURIComponent(JSON.stringify(where))
}

export async function resendReceiptEmail(transactionId, email, onSuccess, onError) {
    const data = {
        email: email
    }
    var url = Config.api() + '/transactions/' + transactionId + '/receipt-email'
    post(url, data, onSuccess, onError)
}

export async function resendTicketEmail(transactionId, email, onSuccess, onError) {
    const data = {
        email: email
    }
    var url = Config.api() + '/transactions/' + transactionId + '/ticket-email'
    post(url, data, onSuccess, onError)
}

export async function refundTransaction(id, onSuccess, onError) {
    var url = Config.api() + '/transactions/' + id + '/refund'
    get(url, onSuccess, onError)
}

export async function completeTransaction(id, onSuccess, onError) {
    var url = Config.api() + '/transactions/' + id + '/complete'
    get(url, onSuccess, onError)
}

export async function getTransactionById(id, onSuccess, onError) {
    var url = Config.api() + '/transactions/' + id
    get(url, onSuccess, onError)
}

export async function getPackageTransactionByTransaction(transactionId, onSuccess, onError) {
    let filterObject = {
        where: {
            transaction_id: transactionId
        }
    }
    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/package-transactions/' + filter
    get(url, onSuccess, onError)
}

export async function getMemberVerifications(memberId, organizationId, onSuccess, onError) {
    let filterObject = {
        where: {
            member_id: memberId,
            organization_id: organizationId
        },
        order: ['created_at DESC'],
        include: [
            {
                relation: 'media'
            },
            {
                relation: 'member'
            },
            {
                relation: '_package'
            }
        ]
    }
    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/member-verifications/' + filter
    get(url, onSuccess, onError)
}

export async function completePayoutById(id, notes, onSuccess, onError) {
    let data = {
        notes: notes
    }
    var url = Config.api() + '/payouts/' + id + '/complete'
    patch(url, data, onSuccess, onError)
}

export async function getPayouts(filterObject, onSuccess, onError) {
    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/payouts/' + filter
    get(url, onSuccess, onError)
}

export async function getPayoutsByOrganizationId(organizationId, onSuccess, onError) {
    let filterObject = {
        where: {
            organization_id: organizationId
        },
        include: [
            {
                relation: 'transactions'
            }
        ],
        order: ['created_at DESC']
    }
    getPayouts(filterObject, onSuccess, onError)
}

export async function deletePayoutById(id, onSuccess, onError) {
    var url = Config.api() + '/payouts/' + id
    _delete(url, onSuccess, onError)
}

export async function getTransactionDetails(from, to, packageId, eventId, ticketId, onSuccess, onError) {
    var url = Config.api() + '/transaction/transaction-details/from/' + from + '/to/' + to + '/package/' + packageId + '/event/' + eventId + '/ticket/' + ticketId
    get(url, onSuccess, onError)
}

export async function getPayoutTransactionDetails(id, onSuccess, onError) {
    var url = Config.api() + '/payouts/' + id + '/transaction-details'
    get(url, onSuccess, onError)
}

export async function getPayoutById(id, onSuccess, onError) {
    let filterObject = {
        include: [
            {
                relation: 'transactions',
                scope: {
                    include: [
                        {
                            relation: 'package_transactions',
                            scope: {
                                include: [
                                    {
                                        relation: 'package'
                                    }
                                ]
                            }
                        },
                        {
                            relation: 'ticket_transactions',
                            scope: {
                                include: [
                                    {
                                        relation: 'tickets',
                                        scope: {
                                            include: [
                                                {
                                                    relation: 'organization_event_occurrences',
                                                    scope: {
                                                        include: [
                                                            {
                                                                relation: 'organization_event'
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                relation: 'organization',
                scope: {
                    include: [
                        {
                            relation: 'organization_details'
                        }
                    ]
                }
            }
        ]
    }
    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/payouts/' + id + filter
    get(url, onSuccess, onError)
}

export async function getPayoutsByYearAndMonth(year, month, filterObject, onSuccess, onError) {
    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/payouts/year/' + year + '/month/' + month + filter
    get(url, onSuccess, onError)
}

export async function createPayout(organizationId, transactionIds, notes, from, to, onSuccess, onError) {
    var url = Config.api() + '/payouts'

    let data = {
        organization_id: organizationId,
        transaction_ids: transactionIds,
        notes: notes,
        from_date: from,
        to_date: to
    }

    post(url, data, onSuccess, onError)
}

export async function getPayoutOrganizations(onSuccess, onError) {
    var url = Config.api() + '/payouts/organizations'
    get(url, onSuccess, onError)
}

export async function getPendingTransactions(organizationId, onSuccess, onError) {
    var url = Config.api() + '/transactions/pending/organization/' + organizationId
    get(url, onSuccess, onError)
}

export async function getBillingsByOrganization(organizationId, from, to, onSuccess, onError) {
    var url = Config.api() + '/transactions/billing/from/' + from + '/to/' + to + '/organization/' + organizationId
    get(url, onSuccess, onError)
}

export async function getBillingTransactions(packageId, ticketId, from, to, onSuccess, onError) {
    var url = Config.api() + '/transactions/billing/from/' + from + '/to/' + to + '/package/' + packageId + '/ticket/' + ticketId
    get(url, onSuccess, onError)
}

export async function createTransactionMessage(transactionId, type, message, onSuccess, onError) {
    const url = Config.api() + '/transaction-messages'
    const data = {
        transaction_id: transactionId,
        type: type,
        message: message
    }
    post(url, data, onSuccess, onError)
}

export async function getTransactionEmailLogsByTransactionId(transactionId, onSuccess, onError) {
    let filterObject = {
        where: {     
            and: [
                {
                    reference: transactionId
                },
                {
                    action: 'Email'
                }
            ]
        },
        order: ['created_at ASC']
    }

    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/transaction-logs' + filter
    get(url, onSuccess, onError)
}

export async function getTransactionLogsByTransactionId(transactionId, reference, memberId, onSuccess, onError) {
    // TODO DEV-2355 include 'payment' logs?
    let filterObject = {
        where: {
            or: [
                {
                    or: [
                        {
                            model: 'transaction'
                        },
                        {
                            model: 'membership-subscription'
                        },
                        {
                            model: 'receipt'
                        },
                        {
                            model: 'member-ticket'
                        }
                    ],
                    and: [
                        {
                            reference: transactionId
                        }
                    ]
                },
                {
                    or: [
                        {
                            model: 'payment'
                        }
                    ],
                    and: [
                        {
                            or: [
                                { reference: reference },
                                //{reference: memberId}
                            ]

                        }
                    ]
                }
            ],

        },
        order: ['created_at ASC']
    }

    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/transaction-logs' + filter
    get(url, onSuccess, onError)
}

export async function getTransactionsWithTickets(status, limit, offset, searchTerm, onSuccess, onError) {
    let filterObject = {
        include: [
            {
                relation: 'ticket_transactions',

                scope: {
                    include: [
                        {
                            relation: 'tickets',
                            scope: {
                                include: [
                                    {
                                        relation: "organization_event_occurrences",
                                        scope: {
                                            include: [
                                                {
                                                    relation: "organization_event"
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            relation: 'member'
                        }
                    ]
                }

            }
        ],
        limit: limit,
        skip: offset,
        order: ['updated_at DESC']
    }

    let filter = toEncodedFilterString(filterObject)

    const searchJson = {
        search: searchTerm
    }
    const search = encodeURIComponent(JSON.stringify(searchJson))
    var url = Config.api() + '/transactions/status/' + status + filter + '&search=' + search
    
    return getWithTotalCount(url, (response) => {
        onSuccess(response.data, response.totalCount)
    }, onError)
}

export async function getTransactionsWithPackages(status, limit, offset, searchTerm, onSuccess, onError) {
    let filterObject = {
        include: [
            {
                relation: 'package_transactions',
                scope: {
                    include: [
                        {
                            relation: 'package',
                            scope: {
                                include: [
                                    {
                                        relation: "membershipSubscriptions",
                                        scope: {
                                            include: [
                                                {
                                                    relation: "membership"
                                                },
                                                {
                                                    relation: 'verification_types'
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            relation: 'member'
                        }
                    ]
                }
            },
            {
                relation: 'transaction_messages'
            }
        ],
        limit: limit,
        skip: offset,
        order: ['updated_at DESC']
    }

    let filter = toEncodedFilterString(filterObject)

    const searchJson = {
        search: searchTerm
    }
    const search = encodeURIComponent(JSON.stringify(searchJson))

    var url = Config.api() + '/transactions/status/' + status + filter + '&search=' + search

    return getWithTotalCount(url, (response) => {
        onSuccess(response.data, response.totalCount)
    }, onError)
}

export async function getPackageTransactions(status, onSuccess, onError) {

    var transactionScope = undefined
    if (status >= 0) {
        transactionScope = {
            where: {
                status: {
                    eq: status
                }
            }
        }
    }

    let filterObject = {
        include: [
            {
                relation: 'member'
            },
            {
                relation: 'package',
                scope: {
                    include: [
                        {
                            relation: "membershipSubscriptions",
                            scope: {
                                include: [
                                    {
                                        relation: 'membership'
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                relation: "transaction",
                scope: transactionScope
            }
        ]
    }
    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/package-transactions' + filter

    return getWithTotalCount(url, (response) => {
        let filteredPackageTransactions = response.data.filter((packageTransaction) => {
            return packageTransaction.transaction
        })
        onSuccess(filteredPackageTransactions, response.totalCount)
    }, onError)
}

export async function _delete(url, onSuccess, onError) {
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

async function getWithTotalCount(url, onSuccess, onError) {
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json().then(data => ({
                totalCount: response.headers.get('X-Total-Count'),
                data
            }))
        })
        .then((result) => {
            if (result && result.data && !result.data.error) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function get(url, onSuccess, onError) {
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error ?? "No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function post(url, body, onSuccess, onError) {
    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function patch(url, body, onSuccess, onError) {
    var token = await getToken()
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function patchMembership(membership, onSuccess, onError) {
    var url = Config.api() + '/memberships/' + membership.id
    var token = await getToken()
    // membership = removeNullValues(membership)
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(membership)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getOrganizationById(organizationId, onSuccess, onError) {

    var url = Config.api() + '/organizations/' + organizationId + '?filter[include][]=organization_details'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No organizations with id", organizationId)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getOrganizationByIdWithTags(organizationId, onSuccess, onError) {

    let filterObject = {
        include: [
            {
                relation: 'organization_details',
                scope: {
                    include: [
                        {
                            relation: 'organization_detail_tags',
                            scope: {
                                include: [
                                    {
                                        relation: 'organization_tag'
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        ]
    }

    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/organizations/' + organizationId + filter
    get(url, onSuccess, onError)
}

// export async function getOrganization(onSuccess, onError) {
//     var url = Config.api() + '/organization/'
//     var token = await getToken()
//     fetch(url, {
//         headers: {
//             'Authorization': 'Bearer ' + token
//         }
//     })
//         .then((response) => {
//             return response.json()
//         })
//         .then((result) => {
//             if (result) {
//                 onSuccess(result)
//             } else {
//                 onError("No organization found")
//             }
//         })
//         .catch(err => {
//             onError(err)
//         })
// }

export async function getOrganizationsByParentId(parentId, onSuccess, onError) {
    var url = Config.api() + '/organizations/parent/' + parentId + '/organizations'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && result.length > 0) {
                onSuccess(result)
            } else {
                onError("No organizations with parent id", parentId)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getOrganizationPost(id, onSuccess, onError) {
    var url = Config.api() + '/organization-posts/' + id
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("Organization post not found")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getOrganizationProduct(id, onSuccess, onError) {
    var url = Config.api() + '/organization-products/' + id
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("Organization product not found")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getMembershipsByOrganizationChain(organizationChain, onSuccess, onError) {
    const filterObject = {
        order: ['valid_to DESC']
    }
    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/memberships/organization-chain/' + organizationChain + filter
    get(url, onSuccess, onError)
}

export async function getOrganizationEventAttendees(id, onSuccess, onError) {
    var url = Config.api() + '/organization-events/' + id + '/member-tickets'
    get(url, onSuccess, onError)
}

export async function getAttendeesByOccurrence(id, onSuccess, onError) {
    const url = Config.api() + '/organization-event-occurrences/' + id + '/member-tickets' 
    get(url, onSuccess, onError)
}

export function downloadBlob(blob, fileName) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName
    link.click();
    window.URL.revokeObjectURL(link.href);
}

export async function getAllMembersAsCsv(onSuccess, onError) {
    const url = Config.api() + '/members/csv'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.blob()
        })
        .then((result) => {
            onSuccess(result)
        })
        .catch(err => {
            onError(err)
        })
}

export async function getGuestsByOccurrenceAsCsv(id, onSuccess, onError) {
    const url = Config.api() + '/organization-event-occurrences/' + id + '/guests/csv'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.blob()
        })
        .then((result) => {
            onSuccess(result)
        })
        .catch(err => {
            onError(err)
        })
}

export async function getAttendeesByOccurrenceAsCsv(id, onSuccess, onError) {
    const url = Config.api() + '/organization-event-occurrences/' + id + '/member-tickets/csv'

    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.blob()
        })
        .then((result) => {
            onSuccess(result)
        })
        .catch(err => {
            onError(err)
        })
}

export async function getSwishInfo(onSuccess, onError) {
    var url = Config.api() + '/transactions/swish/info'
    get(url, onSuccess, onError)
}

export async function getStripeInfo(onSuccess, onError) {
    var url = Config.api() + '/transactions/stripe/info'
    get(url, onSuccess, onError)
}

export async function getTransactionFromProvider(paymentReference, onSuccess, onError) {
    var url = Config.api() + '/transactions/' + paymentReference + '/debug'
    get(url, onSuccess, onError)
}

export async function verifyMembershipImageQR(memberMembershipId, hash, onSuccess, onError) {
    var url = Config.api() + '/verify/member-memberships/' + memberMembershipId + '/hash/' + hash
    get(url, onSuccess, onError)
}

// TICKET ROLE endpoints
export async function validateMemberTicket(hash, onSuccess, onError) {
    var url = Config.api() + '/member-tickets/' + hash + '/validate'
    get(url, onSuccess, onError)
}

export async function getMemberTicketByHash(hash, onSuccess, onError) {
    var url = Config.api() + '/member-tickets/hash/' + hash
    get(url, onSuccess, onError)
}

export async function getMemberTicketValidations(hash, onSuccess, onError) {
    var url = Config.api() + '/member-tickets/hash/' + hash + '/member-ticket-validations'
    get(url, onSuccess, onError)
}

export async function validateMemberTicketForOccurrence(occurrenceId, hash, onSuccess, onError) {
    var url = Config.api() + '/member-tickets/' + hash + '/occurrence/' + occurrenceId + '/validate'
    get(url, onSuccess, onError)
}

export async function getOrganizationEventsWithTickets(organizationId, onSuccess, onError) {
    var url = Config.api() + '/organization-events/tickets?organization_id=' + organizationId
    get(url, onSuccess, onError)
}
// /TICKET ROLE endpoints

export async function getOrganizationTags(order, onSuccess, onError) {

    let filterObject = {
        order: order
    }
    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/organization-tags' + filter
    get(url, onSuccess, onError)
}

export async function createOrganizationEventTag(organizationTagId, organizationEventId, onSuccess, onError) {
    var url = Config.api() + '/organization-event-tags'

    const data = {
        organization_event_id: organizationEventId,
        organization_tag_id: organizationTagId
    }

    post(url, data, onSuccess, onError)
}

export async function deleteOrganizationEventTag(organizationEventTagId, onSuccess, onError) {
    var url = Config.api() + '/organization-event-tags/' + organizationEventTagId

    _delete(url, onSuccess, onError)
}

export async function createOrganizationDetailTag(organizationDetailId, organizationTagId, onSuccess, onError) {
    var url = Config.api() + '/organization-detail-tags'

    const data = {
        organization_detail_id: organizationDetailId,
        organization_tag_id: organizationTagId
    }

    post(url, data, onSuccess, onError)
}


export async function deleteOrganizationDetailTag(organizationDetailTagId, onSuccess, onError) {
    var url = Config.api() + '/organization-detail-tags/' + organizationDetailTagId

    _delete(url, onSuccess, onError)
}

export async function getOrganizationEvent(id, onSuccess, onError) {

    let filterObject = {
        include: [
            {
                relation: 'organization',
                scope: {
                    fields: ['id', 'name', 'display_name']
                }
            },
            {
                relation: 'organization_event_occurrences',
                scope: {
                    include: [
                        {
                            relation: 'tickets',
                            scope: {
                                include: [
                                    {
                                        relation: 'forms'
                                    },
                                    {
                                        relation: 'ticket_forms',
                                        scope: {
                                            include: [
                                                {
                                                    relation: 'form'
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        relation: 'campaigns',
                                    },
                                ]
                            }
                        },
                        {
                            relation: 'organization_event_occurrence_guests'
                        },
                        {
                            relation: 'options'
                        }
                    ],
                    order: ['start_date DESC']
                },
            },
            {
                relation: 'organization_event_tags',
                scope: {
                    include: [
                        {
                            relation: 'organization_tag'
                        }
                    ],
                }
            }
        ],
    } 

    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/organization-events/' + id + filter
    var token = await getToken()

    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("Organization event not found")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getAllOrganizationEvents(skip, where, order, onSuccess, onError) {
    let filterObject = {
        skip: skip,
    }

    if (where) {
        filterObject.where = where
    }
    if (order) {
        filterObject.order = order
    }

    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/organization-events' + filter

    return getWithTotalCount(url, (response) => {
        onSuccess(response.data, response.totalCount)
    }, onError)
}

export async function getOrganizationEvents(organizationId, skip, where, order, onSuccess, onError) {

    let filterObject = {
        skip: skip,
    }

    if (where) {
        filterObject.where = where
    }
    if (order) {
        filterObject.order = order
    }

    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/organizations/' + organizationId + '/organization-events' + filter

    return getWithTotalCount(url, (response) => {
        onSuccess(response.data, response.totalCount)
    }, onError)
}

export async function getOrganizationPosts(organizationId, skip, where = '', onSuccess, onError) {
    var url = Config.api() + '/organizations/' + organizationId + '/organization-posts?filter[skip]=' + skip + where
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json().then(posts => ({
                totalCount: response.headers.get('X-Total-Count'),
                posts
            }))
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("Organization posts not found")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function createOrganizationEventOccurrenceGuest(occurrenceId, name, comment, onSuccess, onError) {
    const url = Config.api() + '/organization-event-occurrences/' + occurrenceId + '/organization-event-occurrence-guests'

    const data =  {
        organization_event_occurrence_id: occurrenceId,
        name: name,
        comment: comment
    }

    post(url, data, onSuccess, onError)
}

export async function getOrganizationEventOccurrenceGuests(occurrenceId, onSuccess, onError) {
    let filterObject = {
        order: ['validated ASC']
    }
    let filter = toEncodedFilterString(filterObject)

    const url = Config.api() + '/organization-event-occurrences/' + occurrenceId + '/organization-event-occurrence-guests' + filter
    get(url, onSuccess, onError)
}

export async function patchOrganizationEventOccurrenceGuest(id, data, onSuccess, onError) {
    const url = Config.api() + '/organization-event-occurrence-guests/' + id
    patch(url, data, onSuccess, onError)
}

export async function deleteOrganizationEventOccurrenceGuest(id, onSuccess, onError) {
    const url = Config.api() + '/organization-event-occurrence-guests/' + id
    _delete(url, onSuccess, onError)
}

export async function getOrganizationProducts(organizationId, skip, where = '', onSuccess, onError) {
    var url = Config.api() + '/organizations/' + organizationId + '/organization-products?filter[skip]=' + skip + where
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json().then(products => ({
                totalCount: response.headers.get('X-Total-Count'),
                products
            }))
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("Organization products not found")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getAllOrganizationDetails(onSuccess, onError) {
    var url = Config.api() + '/organization-details/'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("Organization details not found")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getOrganizationDetails(id, onSuccess, onError) {
    var url = Config.api() + '/organization-details/org/' + id
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            onSuccess(result)
        })
        .catch(err => {
            onError(err)
        })
}

export async function getOrganizationsWithFilter(filterObject, onSuccess, onError) {
    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/organizations' + filter
    get(url, onSuccess, onError)
}

export async function getOrganizations(onSuccess, onError) {
    var url = Config.api() + '/organizations'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && result.length > 0) {
                onSuccess(result)
            } else {
                onError("No organizations")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getAllOrganizationParents(organizationId, onSuccess, onError) {
    var url = Config.api() + '/organization-relations?filter[where][and][0][organization_id][eq]=' + organizationId + '&filter[include][][relation]=parent'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && result.length > 0) {
                onSuccess(result)
            } else {
                onError("No organization relations")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function createOrganization(organization, onSuccess, onError) {
    var url = Config.api() + '/organizations/'
    var token = await getToken()
    organization = removeNullValues(organization)
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(organization)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function deleteAllPendingDeleteRelations(statePendingDeleteOrganizationRelations, onSuccess, onError) {
    var pendingDeleteRelations = statePendingDeleteOrganizationRelations
    if (pendingDeleteRelations && pendingDeleteRelations.length > 0) {

        for (var i = 0; i < pendingDeleteRelations.length; i++) {
            var pendingDeleteRelation = pendingDeleteRelations[i]
            var result = await awaitDeleteOrganizationRelation(pendingDeleteRelation)
            if (!result) {
                onError("Unable to delete organization relation")
            }
            if (i === pendingDeleteRelations.length - 1) {
                onSuccess()
            }
        }

    } else {
        onSuccess()
    }
}

async function awaitDeleteOrganizationRelation(organizationRelationId) {
    return new Promise(resolve => {
        deleteOrganizationRelation(organizationRelationId, () => {
            resolve(true)
        }, (error) => {
            resolve(false)
        })
    })
}

export async function createAllPendingParentRelations(statePendingParentRelations, organizationId, onSuccess, onError) {
    var pendingParentRelations = statePendingParentRelations
    if (pendingParentRelations && pendingParentRelations.length > 0) {

        for (var i = 0; i < pendingParentRelations.length; i++) {
            var pendingParentRelation = pendingParentRelations[i]
            var result = await awaitCreateOrganizationRelation(organizationId, pendingParentRelation.id)
            if (!result) {
                onError("Unable to save organization relation")
            }
            if (i === pendingParentRelations.length - 1) {
                onSuccess()
            }
        }

    } else {
        onSuccess()
    }
}

export async function createAllPendingChildRelations(statePendingChildRelations, organizationId, onSuccess, onError) {
    var pendingChildRelations = statePendingChildRelations
    if (pendingChildRelations && pendingChildRelations.length > 0) {

        for (var i = 0; i < pendingChildRelations.length; i++) {
            var pendingChildRelation = pendingChildRelations[i]
            var result = await awaitCreateOrganizationRelation(pendingChildRelation.id, organizationId)
            if (!result) {
                onError("Unable to save organization relation")
            }
            if (i === pendingChildRelations.length - 1) {
                onSuccess()
            }
        }

    } else {
        onSuccess()
    }
}

export async function awaitCreateOrganizationRelation(organization_id, parent_id) {
    return new Promise(resolve => {
        createOrganizationRelation(organization_id, parent_id, () => {
            resolve(true)
        }, (error) => {
            resolve(false)
        })
    })
}

export async function createOrganizationRelation(organization_id, parent_id, onSuccess, onError) {

    if (organization_id === null || organization_id === undefined || organization_id <= 0) {
        onError("Invalid organization_id")
        return
    }

    if (parent_id === null || parent_id === undefined || parent_id <= 0) {
        onError("Invalid parent_id")
        return
    }

    let data = {
        organization_id: organization_id,
        parent_id: parent_id
    }
    var url = Config.api() + '/organization-relations'
    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then((response) => {
        return response.json()
    })
        .then((result) => {
            if (result && !result.error) {
                onSuccess()
            } else {
                onError("Could not create verified student info")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function patchOrganization(organization, onSuccess, onError) {
    var url = Config.api() + '/organizations/' + organization.id
    var token = await getToken()
    organization = removeNullValues(organization)
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(organization)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function addMemberMembershipWithOrganizationChain(memberId, membershipId, organizationChain, validFrom, validTo, onSuccess, onError) {
    var data = {
        membership_id: membershipId,
        organization_chain: organizationChain
    }

    if (validFrom) {
        data.valid_from = validFrom
    }

    if (validTo) {
        data.valid_to = validTo
    }

    var url = Config.api() + '/members/' + memberId + '/add-member-membership'
    post(url, data, onSuccess, onError)
}

export async function patchMember(member, onSuccess, onError) {
    var url = Config.api() + '/members/' + member.id
    var token = await getToken()
    member = removeNullValues(member)
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(member)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getMembershipsWithTotalCount(filter, onSuccess, onError) {
    var url = Config.api() + '/memberships' + filter
    return getWithTotalCount(url, (response) => {
        onSuccess(response.data, response.totalCount)
    }, onError)
}

export async function getMemberships(filter, onSuccess, onError) {
    var url = Config.api() + '/memberships' + filter
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && result.length > 0) {
                onSuccess(result)
            } else {
                onError("No memberships")
            }
        })
        .catch(err => {
            onError(err)
        })
}

/**
 * Same as getMemberships() but without 'No memberships' error if results.length = 0 to still show the page
 */
export async function getAllMemberships(filter, onSuccess, onError) {
    var url = Config.api() + '/memberships' + filter
    get(url, onSuccess, onError)
}

export async function patchMemberMembership(memberMembership, onSuccess, onError) {

    if (!memberMembership || !memberMembership.id) {
        onError('No member-membership or id')
    }

    var url = Config.api() + '/member-memberships/' + memberMembership.id
    var token = await getToken()
    memberMembership = removeNullValues(memberMembership)
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(memberMembership)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getLatestMembersCount(skip, membershipId, status, searchTerm, organizationId, where = '', onSuccess, onError) {
    var membershipIdParam = ''
    if (membershipId) {
        membershipIdParam = `&membership_id=${membershipId}`
    }

    var statusParam = ''
    if (status) {
        statusParam = `&status=${status}`
    }

    var organizationParam = ''
    if (organizationId) {
        organizationParam = `&organization_id=${organizationId}`
    }

    const searchJson = {
        search: searchTerm
    }
    const search = encodeURIComponent(JSON.stringify(searchJson))
    
    var url = Config.api() + '/members/latest/count?filter[skip]=' + skip + encodeURI(where) + membershipIdParam + statusParam + organizationParam + "&search=" + search

    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No count found")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getLatestMembers(skip, membershipId, status, searchTerm, organizationId, where = '', onSuccess, onError) {
    var membershipIdParam = ''
    if (membershipId) {
        membershipIdParam = `&membership_id=${membershipId}`
    }

    var statusParam = ''
    if (status) {
        statusParam = `&status=${status}`
    }

    var organizationParam = ''
    if (organizationId) {
        organizationParam = `&organization_id=${organizationId}`
    }

    const searchJson = {
        search: searchTerm
    }
    const search = encodeURIComponent(JSON.stringify(searchJson))
    
    var url = Config.api() + '/members/latest?filter[skip]=' + skip + encodeURI(where) + membershipIdParam + statusParam + organizationParam + "&search=" + search

    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json().then(members => ({
                totalCount: response.headers.get('X-Total-Count'),
                members
            }))
        })
        .then((result) => {
            if (result && result.members && result.members.length > 0) {
                onSuccess(result)
            } else {
                onError("No members found")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function searchMember(input, onSuccess, onError) {
    var startsWithDigit = input.match(/^\d/)

    var url = ''
    if (input.includes('@')) {
        url = Config.api() + '/members/email/' + input
    } else if (startsWithDigit && input.length < 10) { // it's probably a member id
        url = Config.api() + '/members/' + input
    } else if (startsWithDigit && input.length >= 10) {
        url = Config.api() + `/members/${input}/member`
    } else {
        var error = {
            name: 'Error',
            message: 'Invalid search input'
        }
        onError(error)
        return
    }

    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && result.error) {
                onError(result.error)
            } else {
                onSuccess(result)
            }
        })
        .catch(err => {
            onError(err)
        })
}

/**
 * Gets access token from cookie, if not valid (cookie is deleted), fetch a new access token using refresh token
 */
var isRunning = false
async function getToken() {
    if (isRunning) {
        await waitUntil(() => !isRunning)
    }
    var token = await getValidToken()
    return token
}

export async function getValidToken() {
    isRunning = true
    const cookies = new Cookies();
    var token = cookies.get('accessToken')
    return new Promise(resolve => {
        if (token) {
            isRunning = false
            resolve(token)
        } else {
            var grantRefreshToken = cookies.get('refreshToken')
            if (grantRefreshToken) {
                refreshToken(grantRefreshToken, (tokenObject) => {
                    saveToken(tokenObject)
                    isRunning = false
                    resolve(tokenObject.accessToken)
                }, (error) => {
                    removeTokens()
                    isRunning = false
                    window.location.href = '/login'
                    resolve()
                })
            } else {
                removeTokens()
                isRunning = false
                window.location.href = '/login'
                resolve()
            }
        }
    })
}

export function saveToken(tokenObject) {
    const cookies = new Cookies();
    var secure = true
    if (window.location.hostname === 'localhost' || window.location.hostname === 'localhost') {
        secure = false
    }
    var maxAge = parseInt(tokenObject.expiresIn) / 1000
    cookies.set('accessToken', tokenObject.accessToken, { path: '/', sameSite: 'Strict', secure: secure, maxAge: maxAge });
    if (tokenObject.refreshToken) {
        cookies.set('refreshToken', tokenObject.refreshToken, { path: '/', sameSite: 'Strict', secure: secure });
    }
}

export function refreshToken(refreshToken, onSuccess, onError) {
    var url = Config.api() + '/user/refresh'
    var refreshGrant = {
        refreshToken: refreshToken
    }
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(refreshGrant)
    }).then((response) => {
        return response.json()
    })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No accessToken")
            }
        })
        .catch(err => {
            onError(err)
        })

}

export function forceRefreshToken() {
    const cookies = new Cookies();
    var grantRefreshToken = cookies.get('refreshToken')
    return new Promise(resolve => {
        if (grantRefreshToken) {
            refreshToken(grantRefreshToken, (tokenObject) => {
                saveToken(tokenObject)
                resolve(tokenObject.accessToken)
            }, (error) => {
                removeTokens()
                window.location.href = '/login'
                resolve()
            })
        }
    })
}


export async function fetchOrganization(organizationId, onSuccess, onError) {
    var url = Config.api() + '/organizations/' + organizationId
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export function logInUser(email, password, onSuccess, onError) {
    var credentials = {
        email: email,
        password: password
    }
    let url = Config.api() + '/user/login'
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(credentials)
    }).then((response) => {
        return response.json()
    })
        .then((result) => {
            // if (result && result.token) {
            //     onSuccess(result.token)
            // } else {
            //     onError(result.error)
            // }
            if (result) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export function SignUpUser(email, password, onSuccess, onError) {
    var credentials = {
        email: email,
        password: password
    }
    let url = Config.api() + '/user/signup'
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(credentials)
    }).then((response) => {
        return response.json()
    })
        .then((result) => {
            if (result.email) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}


export function resetPasswordUserRequest(email, onSuccess, onError) {
    var credentials = {
        email: email,
    }
    let url = Config.api() + '/user/reset-password-request'
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(credentials)
    }).then((response) => {
        return response.json()
    })
        .then((result) => {
            if (result.message) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })

}

export function resetPasswordUser(token, newPassword, confirmPassword, onSuccess, onError) {
    var credentials = {
        token: token,
        newPassword: newPassword,
        confirmPassword: confirmPassword
    }
    let url = Config.api() + '/user/reset-password'
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(credentials)
    }).then((response) => {
        return response.json()
    })
        .then((result) => {
            if (result.message) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export function checkUserResetPasswordToken(token, onSuccess, onError) {
    var url = Config.api() + '/user/check-reset-token/' + token
    fetch(url)
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function saveMemberMembershipAsync(memberId, membershipId, organizationChain, validFrom, validTo) {
    return new Promise(resolve => {
        addMemberMembershipWithOrganizationChain(memberId, membershipId, organizationChain, validFrom, validTo, (result) => {
            resolve(true)
        }, (error) => {
            resolve(false)
        })
    })
}

export async function patchMemberMembershipAsync(memberMembership) {
    return new Promise(resolve => {
        patchMemberMembership(memberMembership, () => {
            resolve(true)
        }, (error) => {
            resolve(false)
        })
    })
}

export async function deleteMemberMembershipsByIdAsync(memberMembershipId) {
    return new Promise(resolve => {
        deleteMemberMembershipsById(memberMembershipId, () => {
            resolve(true)
        }, (error) => {
            resolve(false)
        })
    })
}

async function deleteMemberMembershipsById(memberMembershipId, onSuccess, onError) {
    var url = Config.api() + '/member-memberships/' + memberMembershipId
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export function createVerifiedStudentInfo(verifiedStudentInfo, onSuccess, onError) {
    if (verifiedStudentInfo.personal_identification_number_short.length === 12) {
        verifiedStudentInfo.personal_identification_number_short = verifiedStudentInfo.personal_identification_number_short.substring(2)
    }

    var now = formatDate()
    verifiedStudentInfo.created_at = now
    verifiedStudentInfo.updated_at = now

    postVerifiedStudentInfo(verifiedStudentInfo, () => {
        onSuccess()
    }, (error) => {
        onError(error)
    })

}

async function postVerifiedStudentInfo(verifiedStudentInfo, onSuccess, onError) {
    var url = Config.api() + '/verified-student-infos/'
    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(verifiedStudentInfo)
    }).then((response) => {
        return response.json()
    })
        .then((result) => {
            if (result && !result.error) {
                onSuccess()
            } else {
                onError("Could not create verified student info")
            }
        })
        .catch(err => {
            onError(err)
        })
}

/**
 * Gets last period found by @param organizationId 
 */
export async function getPeriod(organizationId, onSuccess, onError) {
    var url = Config.api() + '/organizations/' + organizationId + '/period'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getLatestOrTopOrganization(memberId, onSuccess, onError) {
    getLatestOrganization(memberId, (organization) => {
        onSuccess(organization)
    }, (error) => {
        getOrganizationById(200, (organization) => {
            onSuccess(organization)
        }, (error) => {
            onError(error)
        })
    })
}

export async function getLatestOrganization(memberId, onSuccess, onError) {
    var url = Config.api() + '/members/' + memberId + '/latest-organization'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export function getLatestPeriod(memberId, onSuccess, onError) {
    getLatestOrTopOrganization(memberId, (organization) => {
        if (organization && organization.id) {
            getPeriod(organization.id, (period) => {
                if (period) {
                    onSuccess(period)
                } else {
                    onError('No period')
                }
            }, (error) => {
                onError(error)
            })
        } else {
            onError('No organization id found')
        }
    }, (error) => {
        onError(error)
    })
}

export function patchVerifiedStudentInfoForSemester(verifiedStudentInfo, onSuccess, onError) {

    if (!verifiedStudentInfo) {
        onError("No verifiedStudentInfo")
    }

    var now = formatDate()
    verifiedStudentInfo.updated_at = now

    // remove null values from object
    verifiedStudentInfo = removeNullValues(verifiedStudentInfo)

    if (parseInt(verifiedStudentInfo.study_pace) === 0) {
        deleteVerifiedStudentInfo(verifiedStudentInfo, () => {
            onSuccess()
        }, (error) => {
            onError(error)
        })
    } else {
        patchVerifiedStudentInfo(verifiedStudentInfo, () => {
            onSuccess()
        }, (error) => {
            onError(error)
        })
    }

}

async function patchVerifiedStudentInfo(verifiedStudentInfo, onSuccess, onError) {

    if (!verifiedStudentInfo.id || !verifiedStudentInfo.personal_identification_number_short || !verifiedStudentInfo.valid_from || !verifiedStudentInfo.valid_to) {
        onError("Bad values")
        return
    }

    var url = Config.api() + '/verified-student-infos/' + verifiedStudentInfo.id
    var token = await getToken()
    verifiedStudentInfo = removeNullValues(verifiedStudentInfo)
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(verifiedStudentInfo)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

async function deleteVerifiedStudentInfo(verifiedStudentInfo, onSuccess, onError) {
    var url = Config.api() + '/verified-student-infos/' + verifiedStudentInfo.id
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function fetchVerifiedStudentInfo(personalIdentificationNumber, onSuccess, onError) {
    var personalIdentificationNumberShort = personalIdentificationNumber
    if (personalIdentificationNumber && personalIdentificationNumber.length === 12) {
        personalIdentificationNumberShort = personalIdentificationNumberShort.substring(2)
    } else {
        return
    }
    var url = Config.api() + '/verified-student-infos/' + personalIdentificationNumberShort
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result.error) {
                onError("No verified student info found")
            } else {
                onSuccess(result)
            }
        })
        .catch(err => {
            onError(err)
        })
}

async function fetchOrganizationAsync(organizationId) {
    return new Promise(resolve => {
        fetchOrganization(organizationId, (organization) => {
            resolve(organization)
        }, (error) => {
            resolve()
        })
    })
}

export async function fetchMembershipsAndOrganizations(memberMembership, onSuccess, onError) {
    var chain = memberMembership.organization_chain.replace(/\s+/g, ''); // remove whitespaces
    var organizationChainArray = chain.split(",");

    var organizations = []

    for (var i = 0; i < organizationChainArray.length; i++) {

        var organizationId = organizationChainArray[i]
        var organization = await fetchOrganizationAsync(organizationId)

        if (organization) {
            organizations.push(organization)
        }
        if (i === organizationChainArray.length - 1) {
            fetchMembership(memberMembership.membership_id, (membership) => {
                onSuccess(membership, organizations)
            }, (error) => {
                onError(error)
            })

        }
    }

}

export async function getMemberDeleteRequests(memberId, onSuccess, onError) {
    let filterObject = {
        where: {     
            and: [
                {
                    reference: memberId.toString()
                },
                {
                    model: 'member-delete-request'
                }
            ]
        },
        order: ['created_at ASC']
    }

    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/transaction-logs' + filter
    get(url, onSuccess, onError)
}

export async function deleteTransactionLogById(id, onSuccess, onError) {
    var url = Config.api() + '/transaction-logs/' + id
    _delete(url, onSuccess, onError)
}

export async function deleteTransactionLogByIdPromise(id) {
    return new Promise((resolve, reject) => {
        deleteTransactionLogById(id, (response) => {
            resolve(true)
        }, (error) => {
            resolve(false)
        })
    })
}

export async function deleteTransactionLogs(transactionLogs, onSuccess, onError) {
    if (!transactionLogs || transactionLogs.length <= 0) {
        onSuccess()
        return
    }

    for (var i = 0; i < transactionLogs.length; i++) {
        var transactionLog = transactionLogs[i]
        await deleteTransactionLogByIdPromise(transactionLog.id)
    }
    onSuccess()
}

export async function deleteMember(memberId, message, onSuccess, onError) {
    var url = Config.api() + '/members/' + memberId + '/' + message
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                response.json().then(function (data) {
                    if (data.success === 'true') {
                        onSuccess()
                    } else {
                        onError(data.error.code)
                    }
                });
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

/**
 * Gets members counts 
 */
export async function getMembersCount(extra = '', onSuccess, onError) {

    var url = Config.api() + '/members/count' + extra

    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}


/**
 * Gets members counts 
 */
export async function getMembersCountLast6Months(onSuccess, onError) {

    var url = Config.api() + '/members/count/months'

    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getDeviceTokenCount(deviceType, onSuccess, onError) {

    var url = Config.api() + '/device-tokens/' + deviceType + '/count'

    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && result.length > 0) {
                onSuccess(result[0])
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function deviceTokenCountLast6Months(onSuccess, onError) {

    var url = Config.api() + '/device-tokens/count/months'

    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}


export async function patchUser(userPasswords, onSuccess, onError) {
    var url = Config.api() + '/user/update'
    var token = await getToken()

    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(userPasswords)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}



export async function changeUserPassword(userData, onSuccess, onError) {
    var url = Config.api() + '/user/change-password'
    var token = await getToken()

    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(userData)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                response.json().then(response => {
                    onError(response.error.message)
                })
            }
        })
        .catch(err => {
            onError(err)
        })
}


export async function getMembersByEmail(email, memberId, onSuccess, onError) {
    var url = Config.api() + '/members/?filter[where][and][0][email][eq]=' + email + '&filter[where][and][1][id][neq]=' + memberId

    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}


export async function getGraduationSemesters(onSuccess, onError) {
    var url = Config.api() + '/graduation-semesters'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}



export async function getEducations(onSuccess, onError) {
    var url = Config.api() + '/educations?filter[include][][relation]=educationDetails'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}


export async function addSegment(name, querySql, queryJson, organization_ids, onSuccess, onError) {
    var data = {
        name: name,
        sql_data: querySql,
        json_data: queryJson,
        created_at: formatDate()
    }
    let url = Config.api() + '/segments?organization_ids=' + organization_ids
    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then((response) => {
        return response.json()
    })
        .then((result) => {
            if (!result.error) {
                onSuccess(result)
            } else {
                onError(result)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function deleteSegmentById(segmentId, onSuccess, onError) {

    var url = Config.api() + '/segments/' + segmentId
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getEventSegments(organizationEventId, onSuccess, onError) {
    let url = Config.api() + '/organization-events/' + organizationEventId + '/notifications/segments'
    get(url, onSuccess, onError)
}

export async function getSegments(skip, where = '', onSuccess, onError) {

    let relation = '&filter[include][]=organization'
    let url = Config.api() + '/segments?filter[skip]=' + skip + where + relation
    var token = await getToken()
    fetch(url, {
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response.json().then(segments => ({
            totalCount: response.headers.get('X-Total-Count'),
            segments
        }))
    })
        .then((result) => {
            if (!result.segments.error) {
                onSuccess(result)
            } else {
                onError(result)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getSegmentById(segmentId, onSuccess, onError) {
    var url = Config.api() + '/segments/' + segmentId
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                if (result.error) {
                    onError(result.error.message)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function updateSegmentById(segment, onSuccess, onError) {
    var url = Config.api() + '/segments/' + segment.id
    var token = await getToken()
    segment = removeNullValues(segment)
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(segment)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function deleteOrganizationRelation(organizationRelationId, onSuccess, onError) {
    var url = Config.api() + '/organization-relations/' + organizationRelationId
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getOrganizationRelation(parentId, organizationId, organization, onSuccess, onError) {
    var url = Config.api() + '/organization-relations?filter[where][and][0][parent_id][eq]=' + parentId + '&filter[where][and][1][organization_id][eq]=' + organizationId
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && result.length > 0) {
                onSuccess(result, organization)
            } else {
                onError("No organizations")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getChildOrganizations(organizationId, onSuccess, onError) {
    var url = Config.api() + '/organizations?filter[where][and][0][id][eq]=' + organizationId + '&filter[include][][relation]=organizations'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && result.length > 0) {
                onSuccess(result)
            } else {
                onError("No organizations")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function postMedia(file, onSuccess, onError) {
    var url = Config.api() + '/media/files/'
    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        body: new FormData(file)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function downloadMedia(fileName, onSuccess, onError) {
    var url = Config.api() + '/media/files/download/' + fileName
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.blob()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function deleteMediaById(mediaId, onSuccess, onError) {

    var url = Config.api() + '/media/' + mediaId
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("File could not be deleted")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getOrganizationProductMedia(productId, onSuccess, onError) {
    var url = Config.api() + '/organization-products/' + productId + '/organization-product-media'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result) {
                onSuccess(result)
            } else {
                onError("Organization product media not found")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getMedia(skip = 0, onSuccess, onError) {
    var url = Config.api() + '/media?filter[limit]=20&filter[skip]=' + skip + '&filter[order]=id%20DESC'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json().then(media => ({
                totalCount: response.headers.get('X-Total-Count'),
                media
            }))
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getMediaById(mediaId, onSuccess, onError) {
    var url = Config.api() + '/media/' + mediaId
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

function createRecursiveInclude(relationName, depth) {
    if (depth === 0) {
      return null
    }
    
    return {
      relation: relationName,
      scope: {
        include: [
          createRecursiveInclude(relationName, depth - 1)
        ].filter(Boolean) // filter out null values
      }
    };
}

export async function getRootOrganizations(depth, onSuccess, onError) {

    const filterObject = {
        where: {
            root: 1
        },
        include: [
            createRecursiveInclude('organizations', depth)
        ]
    }

    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/organizations' + filter
    get(url, onSuccess, onError)
}

export async function organizationsTree(rootIds, depth, onSuccess, onError) {

    const filterObject = {
        where: {
            id: {
                inq: rootIds
            }
        },
        include: [
            createRecursiveInclude('organizations', depth)
        ]
    }

    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/organizations' + filter
    get(url, onSuccess, onError)
}

export async function getPlasticCard(memberId, onSuccess, onError) {
    var url = Config.api() + '/members/' + memberId + '/plastic-card-order'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            onSuccess(result)
        })
        .catch(err => {
            onError(err)
        })
}


export async function deletePlasticCard(cardId, onSuccess, onError) {
    var url = Config.api() + '/plastic-card-orders/' + cardId
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getSegmentCalcByMemberStatus(segmentId, status, onSuccess, onError) {
    var url = Config.api() + '/segments/' + segmentId + '/calc/' + status
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                if (result.error) {
                    onError(result.error.message)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getSegmentCalc(segmentId, onSuccess, onError) {
    var url = Config.api() + '/segments/' + segmentId + '/calc'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                if (result.error) {
                    onError(result.error.message)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getOrganizationEventOccurrenceNotifications(occurrenceId, onSuccess, onError) {
    const filterObject = {
        include: [
            {
                relation: 'notifications',
                scope: {
                    include: [
                        {
                            relation: 'segment'
                        }
                    ]
                }
            }
        ]
    }
    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/organization-event-occurrences/' + occurrenceId + filter
    get(url, onSuccess, onError)
}

export async function createOrganizationEventOccurrenceNotification(occurrenceId, notification, onSuccess, onError) {
    const data = {
        title: notification?.title,
        text: notification?.text,
        schedule: notification?.schedule,
        segment_id: notification?.segment_id,
        created_at: formatDate()
    }
    var url = Config.api() + '/organization-event-occurrences/' + occurrenceId + '/notifications'
    post(url, data, onSuccess, onError)
}

export async function addNotification(title, text, destination, customer, schedule, segment_id, onSuccess, onError) {
    var data = {
        title: title,
        text: text,
        destination: destination,
        customer: customer,
        schedule: schedule,
        segment_id: segment_id,
        created_at: formatDate(),
    }
    let url = Config.api() + '/notifications'
    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then((response) => {
        return response.json()
    })
        .then((result) => {
            if (!result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getCustomers(onSuccess, onError) {
    var url = Config.api() + '/notifications/customers'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            onSuccess(result)
        })
        .catch(err => {
            onError(err)
        })
}

export async function getSegmentTokenCount(segmentId, onSuccess, onError) {
    var url = Config.api() + '/segments/' + segmentId + '/device-tokens/count'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && result.hasOwnProperty("count") && !result.error) {
                onSuccess(result.count)
            } else {
                if (result.error) {
                    onError(result.error.message)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getNotificationMemberCount(notificationId, onSuccess, onError) {
    var url = Config.api() + '/notifications/' + notificationId + '/members/count'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && result.hasOwnProperty("count") && !result.error) {
                onSuccess(result.count)
            } else {
                if (result.error) {
                    onError(result.error.message)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function deleteNotificationById(notificationId, onSuccess, onError) {

    var url = Config.api() + '/notifications/' + notificationId
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getNotificationById(notificationsId, where, onSuccess, onError) {
    var url = Config.api() + '/notifications/' + notificationsId + where
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                if (result.error) {
                    onError(result.error.message)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function updateNotificationById(notification, onSuccess, onError) {
    var url = Config.api() + '/notifications/' + notification.id
    var token = await getToken()
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(notification)
    })

        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getNotifications(skip, where = '', onSuccess, onError) {
    let url = Config.api() + '/notifications?filter[skip]=' + skip + where
    var token = await getToken()
    fetch(url, {
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response.json().then(notifications => ({
            totalCount: response.headers.get('X-Total-Count'),
            notifications
        }))
    })
        .then((result) => {
            if (result && !result.notifications.error) {
                onSuccess(result)
            } else {
                onError(result)
            }
        })
        .catch(err => {
            onError(err)
        })
}


export async function sendPushTest(push, onSuccess, onError) {
    var url = Config.api() + '/notification/test'
    var token = await getToken()
    push = removeNullValues(push)
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(push)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}


export async function importMember(members, onSuccess, onError) {
    var url = Config.api() + '/verified-organization-members/all'
    var token = await getToken()
    members = removeNullValues(members)
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(members)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}


export async function getBatches(limit, offset, onSuccess, onError) {

    let filterObject = {
        include: [
            {
                relation: 'verifiedOrganizationMembers'
            }],
        limit: limit,
        skip: offset,
    }

    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/verified-organization-batches' + filter

    return getWithTotalCount(url, (response) => {
        onSuccess(response.data, response.totalCount)
    }, onError)
}


export async function deleteBatch(batchId, onSuccess, onError) {
    var url = Config.api() + '/verified-organization-batches/' + batchId
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess("Deleted")
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function createVerifiedOrganizationMember(verifiedOrganizationMember, onSuccess, onError) {
    var url = Config.api() + '/verified-organization-members'
    post(url, verifiedOrganizationMember, onSuccess, onError)
}

export async function getVerifiedOrganizationMembers(organizationId, member, onSuccess, onError) {

    let memberIdentifier = ''
    if (member.personal_identification_number) {
        memberIdentifier = member.personal_identification_number
    } else {
        memberIdentifier = member.email
    }

    let filterObject = {
        where: {
            organization_id: organizationId,
            member_identifier: memberIdentifier
        },
        include: [
            {
                relation: 'period'
            }
        ]
    }
    let filter = toEncodedFilterString(filterObject)

    var url = Config.api() + '/verified-organization-members' + filter
    get(url, onSuccess, onError)
}

export async function deleteVerifiedOrganizationMembers(verifiedMember, onSuccess, onError) {
    var url = Config.api() + '/verified-organization-members/' + verifiedMember
    var token = await getToken()
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess("Deleted")
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}



export async function postMediaDragDrop(file, organization_id = '', onSuccess, onError) {
    var url = Config.api() + '/media/files/' + organization_id

    var formData = new FormData();

    file.forEach((fil, index) => {
        formData.append(`file${index}`, fil);
    });

    var token = await getToken()
    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        body: formData
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}



export async function getStudentVerificationType5(pin, onSuccess, onError) {
    var url = Config.api() + '/verified-student-infos/verification/' + pin + '/5/'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                if (result.error) {
                    onError(result.error.message)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getStudentVerificationType6(pin, onSuccess, onError) {
    var url = Config.api() + '/verified-student-infos/verification/' + pin + '/6/'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                if (result.error) {
                    onError(result.error.message)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}


export async function getVerifyStudentUsers(onSuccess, onError) {
    var url = Config.api() + '/verifyStudent/users'
    var token = await getToken()
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                if (result.error) {
                    onError(result.error.message)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}


export async function changeVerifyStudentUsers(userId, status, onSuccess, onError) {
    var url = Config.api() + '/verifyStudent/' + userId + '/status'
    var token = await getToken()
    let body = {
        "status": status
    }
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(async (response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                let json = await response.json()
                if (json && json.error) {
                    onError(json.error)
                } else {
                    onError("No result")
                }
            }
        })
        .catch(err => {
            onError(err)
        })
}


export async function createVerifyStudentUser(data, onSuccess, onError) {
    var url = Config.api() + '/verifyStudent/users/new/'
    var token = await getToken()
    data = removeNullValues(data)
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getAllForms(onSuccess, onError) {
    var filter = {
        order: 'created_at DESC'
    };
    var encodedFilter = encodeURIComponent(JSON.stringify(filter));
    var url = `${Config.api()}/forms?filter=${encodedFilter}`;
    get(url, onSuccess, onError)
}

export async function createForm(form, onSuccess, onError) {
    var url = Config.api() + '/forms/'
    var token = await getToken()
    form = removeNullValues(form)
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(form)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function getForm(formId, onSuccess, onError) {
    let filterObject = {
        include: [
            {
                relation: 'form_items',
                scope: {
                    include: [
                        {
                            relation: 'form_options'
                        }
                    ]
                }
            }]
    }
    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/forms/' + formId + filter
    var token = await getToken()
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function updateFormById(id, data, onSuccess, onError) {
    var url = Config.api() + '/forms/' + id
    var token = await getToken()
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            if (response && (response.status === 204 || response.status === 200)) {
                onSuccess()
            } else {
                onError("No result")
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function updateFormItem(formItemId, data, onSuccess, onError) {
    var url = Config.api() + '/form-items/' + formItemId
    patch(url, data, onSuccess, onError)
}

export async function updateFormOption(formOptionId, data, onSuccess, onError) {
    var url = Config.api() + '/form-options/' + formOptionId
    patch(url, data, onSuccess, onError)
}




// form-items
// form-form-items  
export async function createFormItems(formItem, onSuccess, onError) {
    var url = Config.api() + '/form-items'
    var token = await getToken()
    formItem = removeNullValues(formItem)
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(formItem)
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            if (result && !result.error) {
                onSuccess(result)
            } else {
                onError(result.error)
            }
        })
        .catch(err => {
            onError(err)
        })
}

export async function createFormFormItems(formFormItem, onSuccess, onError) {
    var url = Config.api() + '/form-form-items'
    post(url, formFormItem, onSuccess, onError)
}

export async function createFormOption(formOption, onSuccess, onError) {
    var url = Config.api() + '/form-options'
    post(url, formOption, onSuccess, onError)
}

export async function createFormItemFormOptions(formItemFormOption, onSuccess, onError) {
    var url = Config.api() + '/form-item-form-options'
    post(url, formItemFormOption, onSuccess, onError)
}

export async function addFormToTicket(ticketId, formId, onSuccess, onError) {
    var url = Config.api() + '/tickets-forms'
    let data = {
        tickets_id: ticketId,
        form_id: formId
    }
    post(url,data, onSuccess, onError)
}

export async function getFormMemberResult(ticketsFormId, onSuccess, onError) {
    let filterObject = {
        where: {
            tickets_form_id: ticketsFormId
        }
    }
    let filter = toEncodedFilterString(filterObject)
    var url = Config.api() + '/form-member-results' + filter
    get(url, onSuccess, onError)
}

export async function getTicketsForms(ticketId, onSuccess, onError) {
    var url = Config.api() + '/tickets/' + ticketId + '/tickets-forms'
    get(url, onSuccess, onError)
}

export async function deleteFormFromTicket(id, onSuccess, onError) {
    var url = Config.api() + '/tickets-forms/' + id 
    _delete(url, onSuccess, onError)
}

export async function deleteFormOption(id, onSuccess, onError) {
    var url = Config.api() + '/form-options/' + id 
    _delete(url, onSuccess, onError)
}

export async function deleteForm(id, onSuccess, onError) {
    var url = Config.api() + '/forms/' + id 
    _delete(url, onSuccess, onError)
}


export async function deleteFormItem(id, onSuccess, onError) {
    var url = Config.api() + '/form-items/' + id 
    _delete(url, onSuccess, onError)
}


export async function getFeeLedgerPeriod(start_date, end_date, onSuccess, onError) {

    var url = Config.api() + '/payouts/fee-ledger'
    let data = {
        start_date, 
        end_date
    }
    post(url,data, onSuccess, onError)
}