import React, { Component } from 'react';
import { Label } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class NoImageClassicEditor extends Component {

  render() {

    return <><Label for="description">{this.props.label ?? "Text"}</Label>
        <CKEditor
            config={{
                removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
            }}
            editor={ClassicEditor}
            data={this.props.data}
            onReady={editor => {
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
                this.props.onChange(data, this.props.dataKey ?? 'content')
            }}
            onBlur={(event, editor) => {
            }}
            onFocus={(event, editor) => {
            }}
        />
    </>
  }

}

export default NoImageClassicEditor;
