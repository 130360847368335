import React, { Component } from 'react';
import EventAttendee  from './EventAttendee';
import FormStatistics from './form/FormStatistics';
import { createOrganizationEventOccurrenceGuest, deleteOrganizationEventOccurrenceGuest, downloadBlob, getAttendeesByOccurrence, getAttendeesByOccurrenceAsCsv, getGuestsByOccurrenceAsCsv, getOrganizationEventOccurrenceGuests, patchOrganizationEventOccurrenceGuest } from '../../../../vibe/helpers/apiHelper';
import { Card, TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Label, CardHeader } from 'reactstrap';
import { prettyPrintDate } from '../../../../vibe/helpers/util';
import classnames from 'classnames';
import SmallTextArea from '../../../elements/form/SmallTextArea';
import GuestAttendees from './GuestAttendees';
import { v4 as uuidv4 } from 'uuid';
import JSZip from "jszip";
import { saveAs } from 'file-saver';

const OCCURRENCE_ALL_ID = -1
const TICKET_ALL_ID = -1

const ALL_TICKETS_OPTION = {
  value: TICKET_ALL_ID,
  label: 'All tickets',
  name: 'All tickets'
}

class EventAttendees extends Component {

  constructor(props) {
    super(props);
    this.state = {
      occurrences: [],
      occurrenceOptions: [],
      memberTickets: [],
      selectedOccurrence: OCCURRENCE_ALL_ID,
      selectedTicket: TICKET_ALL_ID,
      ticketOptions: [],
      event: null,
      error: null,
      loading: true,
      isOpen: [],
      totalResultsCount: 0,
      showStatistics: false,
      formOptionFilter: [],
      activeTab: '1',
      opened: false,
      item: {},
      pendingAddGuests: [],
      pendingDeleteGuests: [],
      currentOccurrenceId: this.props.occurrenceId,
      guestAttendees: [],
      isAttendeeModalOpen: this.props.isOpen,
      selectedGuest: [],
      downloadCsvMessage: ''
    }

    this.toggleCard = this.toggleCard.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleAttendeeModal = this.toggleAttendeeModal.bind(this)
    this.onGuestValidate = this.onGuestValidate.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.deleteGuest = this.deleteGuest.bind(this)
  }

  componentDidMount() {
    let eventId = this.props.id
    let occurrenceId = this.props.occurrenceId
    this.fetchData(eventId, occurrenceId)
  }

  componentDidUpdate(prevProps, prevState) {
    
    this.toggleAllCards(prevState)
  }

  scrollToAttendees () {
    const element = document.getElementById('attendees-result')
    if (element) {
      element.scrollIntoView();
    }
  }

  filterFormOption = (formOption, selectedAnswer) => {
  let filters = this.state.formOptionFilter;
    
  const existingFilterIndex = filters.findIndex(filter =>
    filter.form_item.title === formOption.form_item.title && (filter.value === selectedAnswer || !selectedAnswer) 
  );

  if (existingFilterIndex !== -1) {
    filters.splice(existingFilterIndex, 1); 
  } else {
    filters = []
    filters.push({
      form_item: formOption.form_item,
      value: selectedAnswer
    });
  }

  this.scrollToAttendees();
  this.setState({ formOptionFilter: filters });

  return filters;
  }

  clearFormFilter = () => {
    this.setState({ formOptionFilter: [], isOpen: []});
  }

  displayFormFilter() {
    const currentFilter = this.state.formOptionFilter;
    let currentFilterText = `- current filter: `

    if (currentFilter.length > 0) {
      const filterTitles = currentFilter.map((filter) => filter.form_item.title);
      const filterOptions = currentFilter.map((filter) => filter.value);
      
      if (currentFilter[0].form_item.type === "text") {
        return filterTitles.length > 0 ? currentFilterText += `"${filterTitles.join(', ')}"` : '';
      }
        return filterTitles.length > 0 ? currentFilterText +=  `"${filterTitles.join(', ')} = ${filterOptions}"` : '';
    }
  }

 
  updateTotalResultsCount = () => {
  let totalResultsCount = 0;

  this.state.memberTickets.forEach(memberTicket => {
    if (memberTicket.form_member_results) {
      totalResultsCount += 1;
    }
  });
  return totalResultsCount
  };

  toggleStatisticsDisplay = () => {
    this.setState(prevState => ({
      showStatistics: !prevState.showStatistics 
    }));
  };

  toggleCard = (id) => {
     let opens = this.state.isOpen

     if (opens.includes(id)) {
       opens = opens.filter( open => open !== id)
     } else {
       opens.push(id)
     }

     this.setState({isOpen: opens})
  };

  toggleAllCards (prevState) {

    // Toggle isOpen state on all cards when filter is changed
    const { formOptionFilter } = this.state;
    if (prevState.formOptionFilter !== formOptionFilter) {
      if (formOptionFilter.length === 0) {
        this.setState({ isOpen: [] });
      } else {
        let isOpenIds = this.state.isOpen.slice();
        const matchingMemberTickets = this.getMatchingMemberTickets();
  
        matchingMemberTickets.forEach(memberTicket => {
          const memberId = memberTicket.id;
          const matchesFilter = this.showMemberTicket(memberTicket);
          if (matchesFilter && !isOpenIds.includes(memberId)) {
            isOpenIds.push(memberId);
          } else if (!matchesFilter && isOpenIds.includes(memberId)) {
            isOpenIds = isOpenIds.filter(id => id !== memberId);
          }
        });
  
        this.setState({ isOpen: isOpenIds });
      }
    }
  }

  getFormAnswersCount(matchingMemberTicket){
    let formAnswersCount = 0

    if (matchingMemberTicket) {
      for (const memberTicket of matchingMemberTicket) {
        if (memberTicket.form_member_results && memberTicket.form_member_results?.[0].form_option_results?.length > 0) {
          formAnswersCount++
        }
      }
    }
    return formAnswersCount;
  }

  checkAmountOfScans = (memberTicket) => {
    let timesScanned = 0
    if(memberTicket?.member_ticket_validations?.length > 1) {
      timesScanned = memberTicket.member_ticket_validations.length
      return timesScanned;
    } else {
      return 0;
    }
  }

  checkLatestValidation = (memberTicket) => {
    const validations = memberTicket?.member_ticket_validations

    if(!validations || validations.length === 0){
      return null
    }

    const latestValidation = validations[validations.length -1].created_at

    return latestValidation ;
  }
  
  setGuestAttendees(occurrenceId, save = false) {

    if (!occurrenceId) {
      return
    }

    getOrganizationEventOccurrenceGuests(occurrenceId, (guests) => {
        this.setState({guestAttendees: guests})
        if (save && this.props.onEditGuests) {
          this.props.onEditGuests(occurrenceId, guests)
        }
    }, (error) => {
        console.log(error)
    })
  }

  fetchData(eventId, occurrenceId) {
    let event = this.props?.organizationEvent
    if(!event) {
        this.setState({error: 'Unknown error occurred'})
    }

    getAttendeesByOccurrence(occurrenceId, (attendees) => {
        this.setState({memberTickets: attendees, loading: false, occurrenceId: occurrenceId})
    }, (error) => {
        console.log(error)
    })
    this.setGuestAttendees(occurrenceId)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

toggleModal() {
    this.setState(prevState => ({
        opened: !prevState.opened,
    }))
}

toggleAttendeeModal() {
    this.setState(prevState => ({
        isAttendeeModalOpen: !prevState.isAttendeeModalOpen
    }))
}

isOpen() {
  return this.state.opened
}

onChange(value, key) {
    var item = this.state.item
    item[key] = value
    this.setState({ item: item })
}

onGuestValidate(guest) {
  var guestData = {
      validated: true
  }
  
    if (guest && !guest.validated) {
      patchOrganizationEventOccurrenceGuest(guest.id, guestData, () => {
        this.setGuestAttendees(this.state.currentOccurrenceId)
      }, (error) => {
          console.log(error);
        });
      }
}

async onSaveGuestChanges() {
  await this.savePendingGuests()
  await this.deleteGuests()
  this.setGuestAttendees(this.state.currentOccurrenceId, true)
  this.setState({pendingAddGuests: [], pendingDeleteGuests: [], item: []})
}

async savePendingGuests() {
  if(this.state.pendingAddGuests.length > 0) {
    for(let i = 0; i <  this.state.pendingAddGuests.length; i++) {
      const pendingGuest = this.state.pendingAddGuests[i]
      await new Promise(resolve => {
        createOrganizationEventOccurrenceGuest(pendingGuest.occurrence, pendingGuest.name, pendingGuest.comment, (result) => {
          resolve(true)
        }, (error) => {
          resolve(false)
        })
      })
  }}
}

async deleteGuests () {
  if(this.state.pendingDeleteGuests.length > 0){
      for (let i = 0; i < this.state.pendingDeleteGuests.length; i++) {
          let id = this.state.pendingDeleteGuests[i].id
          await new Promise(resolve => {
              deleteOrganizationEventOccurrenceGuest(id, (result) => {
                  resolve(true)
              }, (error) => {
                  resolve(false)
              })
          })
      }
    }
}

addGuest(occurrence, name, comment) {
  let pendingGuests = [...this.state.pendingAddGuests]

  let id = uuidv4()

  pendingGuests.push({occurrence, name, comment, id})

  this.setState(prevState => ({
    pendingAddGuests: pendingGuests, 
    opened: !prevState.opened}))
}

deleteGuest(id) {
    const isPendingDelete = this.state.pendingDeleteGuests.some(existingId => existingId.id === id);
    const isPendingAdd = this.state.pendingAddGuests.some(existingId => existingId.id === id);
    const isGuestAdded = this.state.guestAttendees.some(guest => guest.id === id)

  if(isPendingDelete) {
    // Cancel delete by clicking again if guest is already pending delete
    const updatedPendingDeleteGuests = this.state.pendingDeleteGuests.filter(existingGuest => existingGuest.id !== id);
    this.setState({
        pendingDeleteGuests: updatedPendingDeleteGuests,
    });
  } else if(isGuestAdded) {
    let pendingDelete = [...this.state.pendingDeleteGuests]
    pendingDelete.push({id})
    this.setState(prevState => ({
        pendingDeleteGuests: pendingDelete, 
    }))
  } else if (isPendingAdd) {
    const updatedPendingAddGuest = this.state.pendingAddGuests.filter(existingGuest => existingGuest.id !== id);
    this.setState({
        pendingAddGuests: updatedPendingAddGuest,
    });
  }
}

renderAddNewGuestModal() {
   
    var title = "Add new Guest"
    return <>
      <Modal isOpen={this.isOpen()} toggle={this.toggleModal} className="previewModal" centered>
        <ModalHeader toggle={this.toggleModal}><strong>{title}</strong></ModalHeader>
          <ModalBody>
            <Form>
              <div>
                <Label for="name">Name</Label>
                  <SmallTextArea defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'name')} />
                  <Label for="name">Comment</Label>
                  <SmallTextArea defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'comment')} /> 
              </div> 
            </Form>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-between'>
              <Button size="lg" className="px-5 mt-4" color="primary" disabled={!this.state.item.name} onClick={() => this.addGuest(this.state.currentOccurrenceId, this.state.item.name, this.state.item.comment)}>Save</Button>
              <Button size="lg" className="px-5 mt-4" onClick={() => this.toggleModal()}>Cancel</Button> 
          </ModalFooter>
      </Modal>
    </>
  }

  onChangeTicket(id) {
    this.setState({selectedTicket: id})
  }

  getOccurrenceById(id) {
    return this.state.occurrences.find(occurrence => occurrence.id === id)
  }

  onChangeOccurrence(id) {

    let selectedOccurrence = this.getOccurrenceById(id)
    if (selectedOccurrence) {

        var options = []

        options.push(ALL_TICKETS_OPTION)

        if (selectedOccurrence.tickets && selectedOccurrence.tickets.length > 0) {
          selectedOccurrence.tickets.forEach((tickets) => {
            let option = {
                value: tickets.id,
                label: tickets.name,
                name: tickets.name
            }
            options.push(option)
          })
          this.setState({ticketOptions: options, selectedTicket: TICKET_ALL_ID, selectedOccurrence: id})
        } else {
          this.setState({ticketOptions: [ALL_TICKETS_OPTION], selectedTicket: TICKET_ALL_ID, selectedOccurrence: id})
        }

    } else if (id === OCCURRENCE_ALL_ID) {
      this.setState({ticketOptions: [ALL_TICKETS_OPTION], selectedTicket: TICKET_ALL_ID, selectedOccurrence: id})
    }

  }

  memberTicketMatchesOccurrence(memberTicket) {
    return this.state.selectedOccurrence === OCCURRENCE_ALL_ID || (memberTicket.tickets && this.memberTicketInFilter(memberTicket.tickets, this.state.selectedOccurrence)) 
  }

  memberTicketMatchesTickets(memberTicket) {
    return this.state.selectedTicket === TICKET_ALL_ID || (memberTicket.tickets && memberTicket.tickets.id === this.state.selectedTicket)
  }

  showMemberTicket(memberTicket) {
    const { formOptionFilter } = this.state;
  
    if (formOptionFilter.length === 0) {
      return (
        this.memberTicketMatchesOccurrence(memberTicket) &&
        this.memberTicketMatchesTickets(memberTicket)
      );
    }
  
    const matchesFilter = formOptionFilter.every(filter => {
      const titleToCheck = filter.form_item.title;
      const answerToCheck = filter.value;
  
      const matchingQuestion = memberTicket.form_member_results?.[0]?.form_option_results?.some(result =>
        result.form_item.title === titleToCheck
      );
  
      if (matchingQuestion) {
        if (filter.form_item.type === "text" && !answerToCheck) {
          return true;
        } else if (answerToCheck) {
          return memberTicket.form_member_results?.[0]?.form_option_results?.some(result =>
            result.form_item.title === titleToCheck && result.form_option.value === answerToCheck
          );
        }
      }
  
      return false;
    });

    return this.memberTicketMatchesOccurrence(memberTicket) && this.memberTicketMatchesTickets(memberTicket) && matchesFilter;
  }
  
  getMatchingMemberTickets() {
    if (!this.state.memberTickets || this.state.memberTickets.length <= 0) {
      return []
    }

    return this.state.memberTickets.filter((memberTicket) => {
      return this.showMemberTicket(memberTicket)
    })
  }

  renderAttendeesWithTicket () {
    let memberTickets = this.state.memberTickets

    return (
    <div className='mt-3'>{memberTickets.map(memberTicket => {
      return <div key={memberTicket.id}>{this.renderAttendee(memberTicket)}</div>})}
    </div>
    )
  }
 
  getTicketDuration(tickets) {
      if (tickets.start_date && tickets.end_date) {
          return prettyPrintDate(tickets.start_date) + ' - ' + prettyPrintDate(tickets.end_date)
        } else if (tickets.start_date) {
            return 'Start: ' + prettyPrintDate(tickets.start_date)
        } else if (tickets.end_date) {
            return 'End: ' + prettyPrintDate(tickets.end_date)
        } else {
            return ''
        }
    }
    
  countAttendees() {
        if (this.state.memberTickets) {
            return this.state.memberTickets.length
        }
  }
    
  countGuestAttendees() {
      if(this.state.guestAttendees) {
          return this.state.guestAttendees.length
      }
  }

  renderOneTab(count) {
    return <>
      <Card>
          <div>
              <Nav tabs style={{background:'none'}}>
              </Nav>
          <TabContent activeTab={this.props.onlyDisplayTab}>
              <TabPane tabId="1">
              {this.props.onlyDisplayTab === '1' && ( <>
                  <Label className='mt-1'>{count} attendees</Label>
                  {this.state.memberTickets && this.state.memberTickets.length > 0 && this.renderAttendeesWithTicket()}
              </>)}
              </TabPane> 
              <TabPane tabId="2">
                  {this.props.onlyDisplayTab === '2' && ( <>
                      <div className='d-flex mt-1 mb-1' style={{flexDirection: 'column'}}>
                          {<Label>{this.countGuestAttendees()} guests</Label>}
                      </div> 
                      {this.state.opened && this.renderAddNewGuestModal()}
                      <GuestAttendees occurrence={this.state.currentOccurrenceId} guestsAttendees={this.state.guestAttendees} onGuestValidate={this.onGuestValidate} onlyDisplayTab={this.props.onlyDisplayTab} />
                  </>)}
              </TabPane>
          </TabContent>
          </div>
      </Card>
  </>
  }

  async downloadCsv() {
    const occurrenceId = this?.state?.occurrenceId
    if (!occurrenceId) {
      return
    }

    const eventAttendeesBlob = await new Promise((resolve, reject) => {
      getAttendeesByOccurrenceAsCsv(occurrenceId, (blob) => {
        resolve(blob)
      }, (error) => {
        resolve(false)
      })
    })

    const guestsBlob = await new Promise((resolve, reject) => {
      getGuestsByOccurrenceAsCsv(occurrenceId, (blob) => {
        resolve(blob)
      }, (error) => {
        resolve(false)
      })
    })

    const attendeesFileName = 'event_attendees_' + occurrenceId + '.csv'
    const guestsFileName = 'event_attendees_guests_' + occurrenceId + '.csv'

    if (eventAttendeesBlob?.size > 0 && guestsBlob?.size > 0) {

      const zipFileName = `event_attendees_${occurrenceId}.zip`
      const files = [
        {name: attendeesFileName, blob: eventAttendeesBlob}, 
        {name: guestsFileName, blob: guestsBlob}
      ]
      this.downloadAttendeesZip(zipFileName, files)
      this.setDownloadCsvMessage(zipFileName + ' downloaded!')

    } else if (eventAttendeesBlob?.size > 0) {

      downloadBlob(eventAttendeesBlob, attendeesFileName)
      this.setDownloadCsvMessage(attendeesFileName + ' downloaded!')

    } else if (guestsBlob?.size > 0) {

      downloadBlob(guestsBlob, guestsFileName)
      this.setDownloadCsvMessage(guestsFileName + ' downloaded!')

    } else {
      this.setDownloadCsvMessage('No atteendees or guests found. No file was downloaded')
    }

  }

  setDownloadCsvMessage(message) {
    this.setState({downloadCsvMessage: message})
    setTimeout(() => {
      this.setState({downloadCsvMessage: ''})
    }, 4000);
  }

  async downloadAttendeesZip(fileName, files) {
    const zip = new JSZip();
      for (const file of files) {
        zip.file(file.name, file.blob);
      }
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, fileName);
  }

  renderTabs() {
    const count = this.countAttendees()
    
    if(this.props.onlyDisplayTab) {
      return this.renderOneTab(count)
  } else {
    return <>
        <Card>
            <div>
                <Nav tabs style={{background:'none'}}>
                <NavItem>
                    <NavLink href="#" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => {this.toggle('1')}}>
                    Attendees
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => {this.toggle('2')}}>
                    Guest list
                    </NavLink>
                </NavItem> 
                </Nav>
                <div style={{float: 'right', textAlign: 'end'}}>
                  <Label className='mt-1' style={{cursor: 'pointer'}} onClick={() => this.downloadCsv()}>Download CSV</Label>
                  {this.state.downloadCsvMessage && <div className='mini-font'>{this.state.downloadCsvMessage}</div>}
                </div>

            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                {this.state.activeTab === '1' && ( <>
                    <Label className='mt-1'>{count} attendees</Label>
                    <div className='d-flex  mt-1' style={{flexDirection: 'column'}}>
                      <div>
                        {this.renderFormStatistics()}
                      </div>
                    </div>
                </>)}
                </TabPane> 
                <TabPane tabId="2">
                    {this.state.activeTab === '2' && ( <>
                        <Label className='mt-1'>{this.countGuestAttendees()} guests</Label>
                        <div className='d-flex  mt-1' style={{flexDirection: 'column'}}>
                            <div>
                            <Button className='mb-4 mt-1' color="primary" onClick={() => this.toggleModal()}><i className="fa fa-plus-circle mr-1" />Add guest</Button>
                            </div>
                        </div> 
                        {this.state.opened && this.renderAddNewGuestModal()}
                        <GuestAttendees occurrence={this.state.currentOccurrenceId} pendingAddGuests={this.state.pendingAddGuests} pendingDeleteGuests={this.state.pendingDeleteGuests} deleteGuest={this.deleteGuest} guestsAttendees={this.state.guestAttendees} />
                    </>)}
                </TabPane>
            </TabContent>
            </div>
        </Card>
    </>
    }
  }

renderAttendeesModal() {
  if(!this.props.onlyDisplayTab) {
    return <>
        <Modal size="xl" scrollable={true} isOpen={this.props.isOpen} toggle={this.props.toggle} className="previewModal" style={{background:'#f2f5f9'}}>
            <ModalHeader toggle={this.props.toggle}></ModalHeader>
            <ModalBody className='modal-body-no-scrollbar'>
                <Form>
                    {this.renderTabs()}
                </Form>
                </ModalBody>
            <ModalFooter className='d-flex justify-content-end'>
                {this.state.pendingAddGuests.length > 0 || this.state.pendingDeleteGuests.length > 0 ? <CardHeader className="text-danger">Unsaved changes. Click 'Save' to save your changes.</CardHeader> : null}
                <Button size='lg' className="px-5" onClick={() => this.onSaveGuestChanges()} color='primary'>Save</Button>
            </ModalFooter>
        </Modal>
    </>
    } else {
      return <>
        <Modal size="lg" contentClassName={"modal-content-no-padding"} scrollable={true} isOpen={this.props.isOpen} toggle={this.props.toggle} className='modal-content-no-padding' style={{background:'#f2f5f9'}}>
            <ModalHeader toggle={this.props.toggle}>{this.props.onlyDisplayTab === '1' ? 'Attendees' : 'Guest list'}</ModalHeader>
            <ModalBody className='modal-body-no-scrollbar' style={this.props.onlyDisplayTab ? {padding: '0'} : null}>
                <Form>
                    {this.renderTabs()}
                </Form>
                </ModalBody>
        </Modal>
    </>
    }
}

  

memberTicketInFilter(tickets, selectedOccurrence) {
  if (!tickets.organization_event_occurrences || tickets.organization_event_occurrences.length <= 0) {
    return false
  }
  
  for(const occurence of tickets.organization_event_occurrences){
    if(occurence.id === selectedOccurrence){
        return true
      }
    }
    return false
  }

  renderAttendees(matchingMemberTickets) {
  
    // Sort matchingMemberTickets alphabetically by first_name
    const sortedAttendees = matchingMemberTickets.sort((a, b) => {
      const firstNameA = a.member.first_name.toUpperCase();
      const firstNameB = b.member.first_name.toUpperCase();
      
      if (firstNameA < firstNameB) {
        return -1;
      }
      if (firstNameA > firstNameB) {
        return 1;
      }
      return 0;
    });
    
    return sortedAttendees.map((memberTicket) => (
      <div key={memberTicket.id}>
        {this.renderAttendee(memberTicket)}
      </div>
    ));
  }
  
  
  renderAttendee(memberTicket) {
    const { isOpen } = this.state;
    const cardIsOpen = isOpen.includes(memberTicket.id)
    
    if (memberTicket.form_member_results) {
      this.updateTotalResultsCount();
    }
    
    
    return <EventAttendee
    memberTicket={memberTicket}
    isOpen={this.state.isOpen} 
    toggleCard={this.toggleCard} 
    cardIsOpen={cardIsOpen}
    checkLatestValidation={this.checkLatestValidation}
    checkAmountOfScans={this.checkAmountOfScans}/>
  }

  renderFormStatistics() {
    let matchingMemberTickets = this.getMatchingMemberTickets()
    let totalResultsCount = this.updateTotalResultsCount()

    return <>
    {this.getFormAnswersCount(matchingMemberTickets) > 0 ? (<>
      {/* <br/> */}
      <Button className='mb-4 mt-1 btn btn-primary' color='primary' onClick={this.toggleStatisticsDisplay}>
        {this.state.showStatistics ? 'Show less' : 'Show form results'}
      </Button> </> ) : null }

        {this.state.showStatistics && ( 
            <FormStatistics
              totalResultsCount={totalResultsCount}
              matchingMemberTickets={matchingMemberTickets}
              filterFormOption={this.filterFormOption}
              getFormAnswersCount={this.getFormAnswersCount}
            />
          )}

          {this.getFormAnswersCount(matchingMemberTickets) > 0 ? (<>
            <div className='mt-3' id="attendees-result">{matchingMemberTickets.length} attendees {this.displayFormFilter()}</div>
          {this.state.formOptionFilter.length > 0 ? (<button className='mb-4 mt-2 btn btn-primary' onClick={this.clearFormFilter}>Clear Filter</button>) : null}
          </> ) : null }

          {/* <div className='mt-3' id="attendees-result">{matchingMemberTickets.length} attendees {this.displayFormFilter()}</div>
          {this.state.formOptionFilter.length > 0 ? (<button className='mb-4 mt-2 btn btn-primary' onClick={this.clearFormFilter}>Clear Filter</button>) : null} */}
          <div className='mt-3'>{this.renderAttendees(matchingMemberTickets)}</div>
    </>
  }
  
  render() {
    if (this.state.error) {
      return <div>Error</div>
    }
  
    if (this.state.loading) {
      return <div>Loading...</div>
    }

    return <>
      {this.renderAttendeesModal()}
    </>
    }
  }
  
export default EventAttendees