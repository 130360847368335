import React, { Component }  from 'react';
import { Button, Label } from "reactstrap";
import OccurrenceListItem from './OccurrenceListItem';

class OccurrenceList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount() {

    }

    render() {
        let occurrences = this.props?.organizationEventOccurrences?.map((occurrence) => {
            return <OccurrenceListItem key={occurrence.id ?? occurrence.key} event={this.props.organizationEvent} new={false} occurrence={occurrence}
                onCloneOccurrence={(occurrence, tickets) => this.props.onCloneOccurrence(occurrence, tickets)}
                onClickEditOccurrence={(occurrence) => this.props.onClickEditOccurrence(occurrence)}
                toggleEventAttendeesModal={(occurrence) => this.props.toggleEventAttendeesModal(occurrence)}
                onClickNotify={(event, occurrence) => this.props.onClickNotify(event, occurrence)}
                onDeleteOccurrence={(occurrence) => this.props.onDeleteOccurrence(occurrence)} />
        })

        return <React.Fragment>
            {occurrences && <Label className='mt-3' for="occurrences">Occurrences</Label>}
            <br/>
            <Button className='mb-4 mt-2' color="primary" onClick={() => this.props.onClickEditOccurrence(null)}><i className="fa fa-plus-circle mr-1" />Add occurrence</Button>
            {occurrences}
        </React.Fragment>
    }

}

export default OccurrenceList;