
// Icons from https://feathericons.com/
const nav = {
  top: [
    {
      name: 'Home',
      url: '/home',
      icon: 'Home',
      role: ['SuperUser', 'Admin', 'Union']
    },
    {
      name: 'Members',
      url: '/members',
      role: ['Admin', 'SuperUser', 'Union'],
      icon: 'Users',
    },
    {
      name: 'Segments',
      url: '/segments',
      icon: 'PieChart',
      role: ['Admin', 'SuperUser', 'Union'],
      allowedUnion: [3403, 4343], // UK, NO
    },
    {
      name: 'Notifications',
      url: '/notifications',
      icon: 'Send',
      role: ['Admin', 'SuperUser', 'Union'],
      allowedUnion: [3403, 4343], // UK, NO
    },
    {
      name: 'Organizations',
      url: '/organizations',
      icon: 'Globe',
      role: ['SuperUser']
    },
    {
      name: 'Memberships',
      url: '/memberships',
      icon: 'CreditCard',
      role: ['Admin', 'SuperUser', 'Union', 'Transaction', 'PackageTransaction'],
      children: [
        {
          name: 'Memberships',
          url: '/memberships',
          icon: 'Layers',
          role: ['SuperUser']
        },
        {
          name: 'Themes',
          url: '/themes',
          icon: 'PenTool',
          role: ['SuperUser'],
        },
        {
          name: 'Subscriptions',
          url: '/membership-subscriptions',
          icon: 'BookOpen',
          role: ['SuperUser', 'Union']
        },
        {
          name: 'Packages',
          icon: 'Package',
          url: '/membership-packages',
          role: ['SuperUser', 'Union']
        },
        {
          name: 'Transactions',
          icon: 'FileText',
          url: '/transactions/packages',
          role: ['SuperUser','Union', 'Transaction', 'PackageTransaction']
        },
        {
          name: 'Members importer',
          icon: 'FilePlus',
          url: '/members-importer',
          role: ['Union']
        }
      ]
    },
    {
      name: 'Events',
      url: '/organization-events',
      icon: 'Calendar',
      role: ['SuperUser', 'Admin', 'Union', 'Transaction'],
      children: [
        {
          name: 'All events',
          icon: 'Trello',
          url: '/organization-events',
          role: ['SuperUser', 'Admin', 'Union']
        },
        {
          name: 'Transactions',
          icon: 'FileText',
          url: '/organization-events/transactions',
          role: ['SuperUser', 'Admin', 'Union', 'Transaction']
        },
      ]
    },
    {
      name: 'Reports',
      url: '/',
      icon: 'BarChart2',
      role: ['SuperUser', 'Admin', 'Union', 'Financial'],
      children: [
        {
          name: 'Payouts',
          url: '/billing',
          icon: 'BarChart2',
          role: ['SuperUser', 'Admin', 'Union']
        },
        {
          name: 'Sales Analytics',
          url: '/analytics',
          icon: 'BarChart2',
          role: ['SuperUser', 'Admin', 'Union']
        },
        {
          name: 'Organizations',
          url: '/payouts/organizations',
          icon: 'DollarSign',
          role: ['SuperUser', 'Financial']
        },
        {
          name: 'Payouts',
          url: '/payouts/all',
          icon: 'DollarSign',
          role: ['SuperUser', 'Financial']
        },
        {
          name: 'Fee Ledger',
          url: '/fee-ledger',
          icon: 'Coffee',
          role: ['SuperUser', 'Financial']
        }
      ]
    },
    {
      name: 'Forms',
      url: '/forms',
      icon: 'FileText',
      role: ['SuperUser', 'Admin', 'Union'],
    },
    {
      name: 'Campaigns',
      url: '/campaigns',
      icon: 'Percent',
      role: ['SuperUser', 'Admin', 'Union'],
    },
    {
      name: 'Scanner',
      icon: 'Smartphone',
      url: '/ticket-scanner',
      role: ['SuperUser', 'Admin', 'Union', 'Scanner']
    },
    {
      name: 'Media',
      url: '/media',
      icon: 'Image',
      role: ['SuperUser', 'Admin', 'Union']
    },
    // {
    //   name: 'Scan membership',
    //   url: '/scan-membership',
    //   icon: 'CreditCard',
    //   role: ['Scanner', 'Scanner']
    // },
    {
      name: 'Verify Student',
      icon: 'Maximize',
      url: '/verify-student',
      role: ['VerifyStudent', 'AdminVerifyStudent']
    },
    // {
    //   name: 'Products',
    //   url: '/organization-products',
    //   icon: 'Box',
    //   role: ['SuperUser', 'Admin', 'Union']
    // },
    {
      divider: true,
    },
    {
      name: 'Manage Users',
      url: '/manage-users',
      icon: 'Users',
      role: ['AdminVerifyStudent']
    },     
    {
      name: 'Profile',
      url: '/profile',
      icon: 'User',
    }, 
    {
      name: 'Settings',
      url: '/organization-details',
      icon: 'Settings',
      role: ['SuperUser', 'Admin', 'Union']
    }   
  ]
};
export default nav
