import Dashboard from './pages/Dashboard';
import Members from './pages/members/Members';
import EditMember from './elements/members/EditMember';
import Profile from './pages/profile/Profile';
import Segments from './pages/segment/Segments';
import SegmentNew from './pages/segment/SegmentNew';
import EditSegment from './elements/segments/EditSegment';
import Organizations from './pages/organization/Organizations';
import Memberships from './pages/membership/Memberships';
import Media from './pages/media/Media';
import Notifications from './pages/notifications/Notifications'
import NotificationNew from './pages/notifications/NotificationNew';
import EditNotification from './elements/notifications/EditNotification';
import EditOrganization from './elements/organization/EditOrganization';
import EditMembership from './elements/membership/EditMembership';
import EditOrganizationDetails from './pages/organization-details/EditOrganizationDetails';
import OrganizationPosts from './pages/organization/post/OrganizationPosts';
import EditOrganizationPost from './pages/organization/post/EditOrganizationPost';
import EditOrganizationEvent from './pages/organization/event/EditOrganizationEvent';
import EventAttendees from './pages/organization/event/EventAttendees';
import OrganizationEvents from './pages/organization/event/OrganizationEvents';
import EditOrganizationProduct from './pages/organization/product/EditOrganizationProduct';
import OrganizationProducts from './pages/organization/product/OrganizationProducts';
import EditPackage from './pages/package/EditPackage';
import EditMembershipSubscription from './pages/subscription/EditMembershipSubscription';
import MembershipSubscriptions from './pages/subscription/MembershipSubscriptions';
import Packages from './pages/package/Packages';
import PackageTransactions from './pages/transaction/PackageTransactions';
import PackageTransactionDetail from './pages/transaction/PackageTransactionDetail';
import NewMember from './pages/members/NewMember';
import ImporterPage from './pages/members-importer/importer-page';
import Billing from './pages/billing/Billing';
import TicketScanner from './pages/organization/event/ticket/TicketScanner';
import TicketTransactions from './pages/transaction/TicketTransactions';
import Payouts from './pages/payout/Payouts';
import PayoutOrganizations from './pages/payout/PayoutOrganizations';
import EditPayoutDetails from './pages/payout/EditPayoutDetails';
import ViewPayoutDetails from './pages/payout/ViewPayoutDetails';
import Forms from './pages/forms/Forms'
import EditForm from './pages/forms/EditForm';
import verifyCheck from './pages/verifyStudent/verifyStudent';
import manageUsers from './pages/manage-users/manageUsers';
import ManageUsersNew from './pages/manage-users/manageUsersNew';
import FeeLedger from './pages/payout/FeeLedger';
import AllPayouts from './pages/payout/AllPayouts';
import SalesAnalytics from './pages/analytics/SalesAnalytics';
import Campaigns from './pages/campaiges/CampaignsIndex.js'
import CampaignForm from './pages/campaiges/CampaignForm.js'
import Themes from './pages/themes/ThemeIndex.js'
import ThemeForm from './pages/themes/ThemeForm.js'

// See React Router documentation for details: https://reacttraining.com/react-router/web/api/Route
const pageList = [
  {
    name: 'Dashboard',
    path: '/home',
    component: Dashboard,
  },
  {
    name: 'New member',
    path: '/members/new',
    component: NewMember,
  },
  {
    name: 'Members',
    path: '/members',
    component: Members,
  },
  {
    name: 'Profile',
    path: '/profile',
    component: Profile
  },
  {
    name: 'Edit Member',
    path: '/member/edit/:id?',
    component: EditMember,
  },
  {
    name: 'Segments',
    path: '/segments',
    component: Segments,
  },  
  {
    name: 'New segment',
    path: '/segment/new',
    component: SegmentNew,
  },
  {
    name: 'Edit segment',
    path: '/segment/edit/:id?',
    component: EditSegment,
  },
  {
    name: 'Notifications',
    path: '/notifications',
    component: Notifications,
  },   
  {
    name: 'New push notification',
    path: '/notification/new',
    component: NotificationNew,
  },  
  {
    name: 'Edit notification',
    path: '/notification/edit/:id?',
    component: EditNotification,
  },
  {
    name: 'Organizations',
    path: '/organizations',
    component: Organizations,
  }, 
  {
    name: 'Edit organization',
    path: '/organization/edit/:id?',
    component: EditOrganization,
  },
  {
    name: 'New organization',
    path: '/organization/new',
    component: EditOrganization,
  },
  {
    name: 'Memberships',
    path: '/memberships',
    component: Memberships,
  }, 
  {
    name: 'Edit membership',
    path: '/membership/edit/:id?',
    component: EditMembership,
  },
  {
    name: 'New membership',
    path: '/membership/new',
    component: EditMembership,
  },
  {
    name: 'Organization details',
    path: '/organization-details/',
    component: EditOrganizationDetails,
  },
  {
    name: 'Edit organization post',
    path: '/organization-posts/edit/:id?',
    component: EditOrganizationPost,
  },
  {
    name: 'New organization post',
    path: '/organization-posts/new/',
    component: EditOrganizationPost,
  },
  {
    name: 'Organization posts',
    path: '/organization-posts/',
    component: OrganizationPosts,
  },
  {
    name: 'Edit organization event',
    path: '/organization-events/edit/:id?',
    component: EditOrganizationEvent,
  },
  {
    name: 'New organization event',
    path: '/organization-events/new/',
    component: EditOrganizationEvent,
  },
  {
    name: 'Organization event attendees',
    path: '/attendees/organization-events/:id?/occurrence/:occurrence?',
    component: EventAttendees,
  },
  {
    name: 'Organization event attendees',
    path: '/attendees',
    component: EventAttendees,
  },
  {
    name: 'Ticket transactions',
    path: '/organization-events/transactions/',
    component: TicketTransactions,
  },
  {
    name: 'Organization events',
    path: '/organization-events/',
    component: OrganizationEvents,
  },
  {
    name: 'Edit organization product',
    path: '/organization-products/edit/:id?',
    component: EditOrganizationProduct,
  },
  {
    name: 'New organization product',
    path: '/organization-products/new/',
    component: EditOrganizationProduct,
  },
  {
    name: 'Organization products',
    path: '/organization-products/',
    component: OrganizationProducts,
  },
  {
    name: 'Media',
    path: '/media',
    component: Media,
  },
  {
    name: 'Transaction details',
    path: '/transactions/packages/edit/:id?',
    component: PackageTransactionDetail
  },
  {
    name: 'Transactions',
    path: '/transactions/packages',
    component: PackageTransactions
  },
  {
    name: 'Edit membership package',
    path: '/membership-packages/edit/:id?',
    component: EditPackage
  },
  {
    name: 'New membership package',
    path: '/membership-packages/new/',
    component: EditPackage,
  },
  {
    name: 'Membership packages',
    path: '/membership-packages',
    component: Packages
  },
  {
    name: 'Edit membership subscription',
    path: '/membership-subscriptions/edit/:id?',
    component: EditMembershipSubscription
  },
  {
    name: 'New membership subscription',
    path: '/membership-subscriptions/new',
    component: EditMembershipSubscription
  },
  {
    name: 'Membership subscription',
    path: '/membership-subscriptions',
    component: MembershipSubscriptions
  },
  {
    name: 'Members importer',
    path: '/members-importer',
    component: ImporterPage
  },
  {
    name: 'Billing',
    path: '/billing',
    component: Billing
  },
  {
    name: 'Analytics',
    path: '/analytics',
    component: SalesAnalytics
  },
  {
    name: 'Edit Payout details',
    path: '/payouts/organization/:id?/payout/:payoutId?/edit',
    component: EditPayoutDetails
  },
  {
    name: 'View Payout details',
    path: '/payouts/organization/:id?/payout/:payoutId?/view',
    component: ViewPayoutDetails
  },
  {
    name: 'Organization payouts',
    path: '/payouts/organization/:id?',
    component: Payouts
  },
  {
    name: 'Organizations',
    path: '/payouts/organizations',
    component: PayoutOrganizations
  },
  {
    name: 'All Payouts',
    path: '/payouts/all',
    component: AllPayouts
  },
  {
    name: 'Ticket scannerxx',
    path: '/ticket-scanner',
    component: TicketScanner
  },
  {
    name: 'Forms',
    path: '/forms',
    component: Forms,
  },   
  {
    name: 'New form',
    path: '/form/new',
    component: EditForm,
  },  
  {
    name: 'Edit form',
    path: '/form/edit/:id?',
    component: EditForm,
  },
  {
    name: 'Verify Student',
    path: '/verify-student',
    component: verifyCheck
  },
  {
    name: 'Manage Users',
    path: '/manage-users',
    component: manageUsers
  },
  {
    name: 'Create User',
    path: '/manage-user/new',
    component: ManageUsersNew
  },
  {
    name: 'Fee Ledger',
    path: '/fee-ledger',
    component: FeeLedger
  },
  {
    name: 'Campaigns',
    path: '/campaigns',
    component: Campaigns
  },

  {
    name: 'New campaign',
    path: '/Campaign/new',
    component: CampaignForm,
  },  
  {
    name: 'Edit campaign',
    path: '/campaign/edit/:id?',
    component: CampaignForm,
  },

  {
    name: 'Themes',
    path: '/themes',
    component: Themes
  },

  {
    name: 'New theme',
    path: '/theme/new',
    component: ThemeForm,
  },  
  {
    name: 'Edit theme',
    path: '/theme/edit/:id?',
    component: ThemeForm,
  },
];

export default pageList;
