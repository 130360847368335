import { getMedia } from "./apiHelper"
import { getCurrentYear, getSwedenDateNow } from "./dateHelper";

const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function hasValue(value) {
    return typeof value !== 'undefined' && value != null
}

export function containsOnlyDigits(string) {
    if(string){
        return string.match(/^\d+$/)
    }else{
        return '-'
    }
}

export function formatNumber(number){
    if (number){
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")    
    }
    return "0"
}

/**
 * 
 * @param {*} firstObject 
 * @param {*} secondObject 
 * @param {*} ignoreKeys - array of key strings that will not be evaluated
 * 
 * @returns an array of objects like: 
 * [
 *  {key: 'name', first: 'John', second: 'Johnny'},
 *  {key: 'age', first: 26, second: 27}
 * ]
 */
export function diffObject(firstObject, secondObject, ignoreKeys) {
    var changes = []

    if (secondObject) {
        for (const [key, value] of Object.entries(secondObject)) {
            if (ignoreKeys.includes(key)) {
                continue;
            }
    
            if (JSON.stringify(value) !== JSON.stringify(firstObject[key])) {
                let diff = {key: key, first: firstObject[key], second: value}
                changes.push(diff)
            }
        }
    }
    
    return changes
}

/**
 * prints all connected membership-subscriptions to this package in format
 * "membership_1.name  duration"
 * 
 * where duration is a string "valid_to: 2022-01-01" or "duration: 100 days"
 * 
 * reversing the string so that the first connected membership shows first
 */
export function renderPackageMembershipSubscriptions(_package) {
    if (_package && _package.membershipSubscriptions && _package.membershipSubscriptions.length > 0) {
        return _package.membershipSubscriptions.map((membershipSubscription) => {
            var validString = getMembershipSubscriptionValidDuration(membershipSubscription)
            return <p key={membershipSubscription.membership.name + '_' + validString} className="list-item-text mb-0">
                {membershipSubscription.membership.name}&nbsp;<span className='bold-600'>{validString}</span>
                </p>
        }).reverse()
    }
    return null
}

export function getTicketQuantity(transaction) {
    if (transaction && transaction.ticket_transactions && transaction.ticket_transactions.length > 0) {
        let ticketTransactionMeta = JSON.parse(transaction.ticket_transactions[0].meta)
        if (ticketTransactionMeta && ticketTransactionMeta.tickets && ticketTransactionMeta.tickets.length > 0) {
            let ticketId = transaction.ticket_transactions[0].tickets_id

            for (var meta of ticketTransactionMeta.tickets) {
                if (meta.id === ticketId ) {
                    return meta.count
                }
            }
        }
    }
    return null
}

export function renderPackageMembershipSubscriptionsDetailed(_package, packageTransaction) {
    if (_package && _package.membershipSubscriptions && _package.membershipSubscriptions.length > 0) {

        return _package.membershipSubscriptions.map((membershipSubscription) => {

            var validText

            if (membershipSubscription.duration_type === 3) {

                if (packageTransaction && packageTransaction.meta) {

                    let packageTransactionMeta = JSON.parse(packageTransaction.meta)

                    if (packageTransactionMeta.membership_subscriptions) {
                        let membershipSubscriptionMeta = packageTransactionMeta.membership_subscriptions.find(el => el.id === membershipSubscription.id)

                        if (membershipSubscriptionMeta) {
                            let membershipStartDate = membershipSubscriptionMeta.membership_start_date
                            var start = new Date(membershipStartDate)
                            var validTo = new Date(start)
                            
                            validTo.setDate(validTo.getDate() + membershipSubscription.duration_days)
                            validTo.setSeconds(validTo.getSeconds() - 1)

                            var validFromDate = prettyPrintDate(start.toISOString())
                            var validToDate = prettyPrintDate(validTo.toISOString())

                            validText = "Valid from: " + validFromDate + ", to: " + validToDate + " (" + membershipSubscription.duration_days + " days)"
                        }
                    }
                }

            } else if (membershipSubscription.duration_type === 2) {
                validText = 'Valid for ' + membershipSubscription.duration_days + " days, starting when member receives membership "
            } else {
                // default
                validText = 'Valid from ' + prettyPrintDate(membershipSubscription.membership.valid_from) + " to " + prettyPrintDate(membershipSubscription.membership.valid_to)
            }

            return <div key={membershipSubscription.id}>
                {/* <p className="list-item-text mb-0">
                    id: {membershipSubscription.membership.id}
                </p> */}
                <p className="list-item-text mb-0">
                    {membershipSubscription.membership.name}
                </p>
                <p className="list-item-text mb-2">
                    {validText}
                </p>
                {_package.membershipSubscriptions.length > 1 && <hr style={{width: '50%', marginLeft: 0, marginRight: 0}} />} 
                </div>
        }).reverse()
    }
    return null
}

export function getMembershipSubscriptionValidDuration(membershipSubscription) {
    var validString = ''
    if (membershipSubscription.duration_days) {
        validString += ' duration: ' + membershipSubscription.duration_days + ' days'
    } else {
        validString += ' valid to: ' + membershipSubscription.membership.valid_to.substr(0, 10)
    } 
    return validString
}

export function getMediaOptions(onSuccess) {
    getMedia( 0, (files) => {
        var options = []
        if (files && files.media.length > 0) {
          files.media.forEach((file) => {
                let fileOption = {
                    value: file.file_path,
                    label: file.file_name,
                    id: file.id
                }
                options.push(fileOption)
            })
        }
        onSuccess(options)
      }, (error) => {
        console.log("Error getting files", error)
      })
}

export function removeNullValues(array) {
    // remove null values from object
    Object.keys(array).forEach(key => {
        if (array[key] === undefined || array[key] === null) {
            delete array[key]
        }
    })
    return array
}

export function getOrganizationSelectOptions(organizations) {
    var options = []
    if (organizations && organizations.length > 0) {
        organizations.forEach((organization) => {
            let organizationOption = {
                value: organization.id,
                label: '' + organization.id + ' | ' + organization.name,
                name: organization.name
            }
            options.push(organizationOption)
        })
    }
    return options
}

export function getGenderSelectOptions() {
    var genders = []
    genders.push({value: 'M', label: 'Male'})
    genders.push({value: 'K', label: 'Female'})
    return genders
}

export function createOption(value, label) {
    return {
        value: value,
        label: label,
        name: label
    }
}

export function insertFirstOption(value, label, list) {
    let firstOption = createOption(value, label)
    list.unshift(firstOption)
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getPackageOptions(packages) {
    var options = []

    if (packages && packages.length > 0) {
        packages.forEach((_package) => {
            let packageOption = {
                value: _package.id,
                label: _package.name,
                name: _package.name
            }
            options.push(packageOption)
        })
    }

    return options
}

export function getEventOptions(organizationEvents) {
    var options = []
    if (organizationEvents && organizationEvents.length > 0) {
        for (var organizationEvent of organizationEvents) {
            let eventOption = {
                value: organizationEvent.id,
                label: organizationEvent.title,
                name: organizationEvent.title
            }
            options.push(eventOption)
        }
    }
    return options
}

export function getTicketOptions(organizationEvents, allowDuplicates = false) {
    var options = []
    if (organizationEvents && organizationEvents.length > 0) {

        for (var organizationEvent of organizationEvents) {

            if (organizationEvent.organization_event_occurrences && organizationEvent.organization_event_occurrences.length > 0) {
                for (var occurrence of organizationEvent.organization_event_occurrences) {

                    if (occurrence.tickets && occurrence.tickets.length > 0) {

                        for (var ticket of occurrence.tickets) {
                            var contains = false
                            for (var option of options) {
                                if (option.value === ticket.id && !allowDuplicates) {
                                    contains = true
                                }
                            }

                            if (!contains) {
                                let ticketOption = {
                                    value: ticket.id,
                                    label: ticket.name,
                                    name: ticket.name
                                }
                                options.push(ticketOption)
                            }
                            
                        }
                    }

                }
            }

        }
  
    }
    return options
}

export function getMembershipSelectOptions(memberships) {
    var options = []
    if (memberships && memberships.length > 0) {
        memberships.forEach((membership) => {
            let membershipOption = {
                value: membership.id,
                label: membership.name,
                name: membership.name
            }
            options.push(membershipOption)
        })
    }
    return options
}

export function getMembershipSubscriptionSelectOptions(membershipSubscriptions) {
    var options = []
    if (membershipSubscriptions && membershipSubscriptions.length > 0) {
        membershipSubscriptions.forEach((membershipSubscription) => {
            let membershipSubscriptionOption = {
                value: membershipSubscription.id,
                label: membershipSubscription.membership.name + ', ' + getMembershipSubscriptionValidDuration(membershipSubscription),
                name: membershipSubscription.id,
                purchase_availability_start_date: membershipSubscription.purchase_availability_start_date,
                purchase_availability_end_date: membershipSubscription.purchase_availability_end_date
            }
            options.push(membershipSubscriptionOption)
        })
    }
    return options
}

export function getPayoutStatus(status) {
    switch (status) {
        case 0:
            return "Pending"

        case 1:
            return "Complete"
    
        default:
            return "Unknown";
    }
}

/**
 * Users with organization in this list should not see the "Everyone" segment when editing package/ticket
 */
export function isStrictSegmentOptionsRole(user) {
    return [2514, 2515, 2516, 2517, 2518, 2519, 2520, 2521, 2522, 2582, 2634, 2815, 2829, 2830, 2831, 3304, 4937, 2635, 2644, 2653, 2662, 2672, 2680, 2689, 2698, 2708, 2709, 2710, 2711, 3215].includes(user.organization_id)
}

export function getPackageSegmentOptions(user, segments) {
    return getStrictSegmentOptions(user, segments)
}

export function getTicketSegmentOptions(user, segments) {
    return getStrictSegmentOptions(user, segments)
}

export function getStrictSegmentOptions(user, segments) {
    var segmentOptions = []

    if (user.role !== 'Union' || !isStrictSegmentOptionsRole(user)) {
        segmentOptions.push({ value: null, label: 'Everyone' })
    }

    if (segments && segments.length > 0) {
        const sortedSegments = segments.sort((a, b) => a.id - b.id);
        for (let segment of sortedSegments) {
            segmentOptions.push({ value: segment.id, label: segment.name })
        }
    }

    return segmentOptions
}

export function getTagOptions(tags) {
    var tagOptions = []

    for (let tag of tags) {
        tagOptions.push({ value: tag.id, label: tag.value})
    }
    return tagOptions
}

export function getVerificationTypeOptions(verificationTypes) {
    var options = []

    if (verificationTypes && verificationTypes.length > 0) {
        verificationTypes.forEach((verificationType) => {
            let verificationTypeOption = {
                value: verificationType.id,
                label: verificationType.name,
                name: verificationType.name
            }
            options.push(verificationTypeOption)
        })
    }


    return options
}

export function shortedPersonalIdentificationNumber(personalIdentificationNumber) {
    var personalIdentificationNumberShort = personalIdentificationNumber
    if (personalIdentificationNumberShort.length === 12) {
        personalIdentificationNumberShort = personalIdentificationNumberShort.substring(2)
    }
    return personalIdentificationNumberShort
}

export function getNotificationSendingData(notification) {
    
    if (!notification || notification.status !== 2) {
        return ""
    }

    let sent = notification.sent ?? 0
    let totalMembers = notification.total_members ?? 0

    if (sent <= 0 || totalMembers <= 0) {
        return ""
    }

    let percent = ((sent * 100) / totalMembers).toFixed(1)

    return "" + sent + " / " + totalMembers + " (" + percent + "%)"
}

export function getNotificationStatusString(status) {
    switch (status) {
        case 0:
            return "Pending"

        case 1:
            return "Scheduled"
        
        case 2: 
            return "Sending"

        case 3: 
            return "Sent"

        default:
            return "INVALID_STATUS"
    }
}

export function removeFirstAndLastChar(string) {
    return string.slice(1, -1)
}

export function prettyPrintDateNoTime(dateString) {
    if (!dateString) {
        return dateString
    }
    let iso = dateString.split('.')[0] // remove .403Z
    let split = iso.split('T')
    let dt = split[0]
    return dt
}

export function prettyPrintDate(dateString) {
    if (!dateString) {
        return dateString
    }
    let iso = dateString.split('.')[0] // remove .403Z
    let split = iso.split('T')
    let dt = split[0] + ' ' + split[1]
    return dt
}

/**
 * 2024-04-25T22:57:00.000Z -> Thu 25 Apr 2024
 */
export function superPrettyPrintDate(dateString) {
    if (!dateString) {
        return
    }

    let date = new Date(dateString)
    
    const day = WEEK_DAYS[date.getUTCDay()];
    const dateNum = date.getUTCDate().toString().padStart(2, '0');
    const month = MONTHS[date.getUTCMonth()];
    const year = date.getUTCFullYear().toString();
    const formattedDate = `${day} ${dateNum} ${month} ${year}`;
    return formattedDate;
}

/**
 * 2024-04-25T22:57:00.000Z -> Thu 25 Apr 2024 20:00
 */
export function superPrettyPrintDateTime(dateString) {
    if (!dateString) {
        return
    }

    let date = new Date(dateString)
    
    const day = WEEK_DAYS[date.getUTCDay()];
    const dateNum = date.getUTCDate().toString().padStart(2, '0');
    const month = MONTHS[date.getUTCMonth()];
    const hour = date.getUTCHours().toString().padStart(2, '0');
    const minute = date.getUTCMinutes().toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString();

    var formattedDate = ''
    if (date.getFullYear() === getCurrentYear()) {
        formattedDate = `${day} ${dateNum} ${month} ${hour}:${minute}`;
    } else {
        formattedDate = `${day} ${dateNum} ${month} ${year} ${hour}:${minute}`;
    }
    formattedDate = formattedDate.toUpperCase()

    return formattedDate;
}

export function prettyOccurrenceDateFormat(start, end) {
    if (!start || !end) {
        return
    }

    let date = new Date(start)
    const day = WEEK_DAYS[date.getUTCDay()];
    const dateNum = date.getUTCDate().toString().padStart(2, '0');
    const month = MONTHS[date.getUTCMonth()];
    const hour = date.getUTCHours().toString().padStart(2, '0');
    const minute = date.getUTCMinutes().toString().padStart(2, '0');
    
    let dateEnd = new Date(end)
    const dayEnd = WEEK_DAYS[dateEnd.getUTCDay()];
    const dateNumEnd = dateEnd.getUTCDate().toString().padStart(2, '0');
    const monthEnd = MONTHS[dateEnd.getUTCMonth()];
    const hourEnd = dateEnd.getUTCHours().toString().padStart(2, '0');
    const minuteEnd = dateEnd.getUTCMinutes().toString().padStart(2, '0');
    
    if (dateNum === dateNumEnd) {
        const formattedDate = `${day} ${dateNum} ${month} ${hour}:${minute} - ${hourEnd}:${minuteEnd}`;
        return formattedDate
    } else {
        const formattedDate = `${day} ${dateNum} ${month} ${hour}:${minute} - ${dayEnd} ${dateNumEnd} ${monthEnd} ${hourEnd}:${minuteEnd}`;
        return formattedDate
    }
}

export function filterValidMemberships(memberships) {
    const now = getSwedenDateNow()
    if (memberships?.length) {
        memberships = memberships.filter((membership) => {
            const validFrom = membership.valid_from ? new Date(membership.valid_from).getTime() : -Infinity;
            const validTo = membership.valid_to ? new Date(membership.valid_to).getTime() : Infinity;
            return now >= validFrom && now <= validTo;
        })
    }
    return memberships
}

/**
 * Parse loopback error into one readable string
 */
export function parseErrorMessage(error, includePath = false) {
    if (!error) {
        return "Something went wrong"
    }

    if (error.details && error.details.length > 0) {
        let details = error.details[0]
        var path = ""
        if (includePath && details.path) {
            path = details.path + " "
        }
        return path + details.message
    } else if (error.message) {
        return error.message
    } else {
        return error
    }
}